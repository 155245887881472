import { Button, Form, Input, Modal, notification, Radio, Select, Spin, Table, Typography } from "antd";
import Column from "antd/lib/table/Column";
import React, { ReactElement, useEffect, useState } from "react";


import { isAPIError, type AuthIntegration, type AuthIntegrationTypes, type Customer } from "../../indexTypes";
import { createAuthIntegration, getAuthIntegrationTypes, getCustomerAuthIntegration } from "../../reportApi";



const IntegrationsPage = ({ customer }: {customer: Customer }) => {
    // While we currently only have AuthIntegrations and each customer can only have one AuthIntegration,
    // we plan to build other integrations. This page was built to be easily extensible to additional
    // types of integrations.
    const [configuredIntegrations, setConfiguredIntegrations] = useState<AuthIntegration[]>([]);
    const [loading, setLoading] = useState(true);
    const [hasAuthIntegration, setHasAuthIntegration] = useState(false);
    const [isAuthIntModalOpen, setIsAuthIntModalOpen] = useState(false);
    const [authIntTypes, setAuthIntTypes] = useState<AuthIntegrationTypes>({});
    const [authIntMetadataFields, setAuthIntMetadataFields] = useState<ReactElement[]>([]);

    const [createAuthIntForm] = Form.useForm();

    useEffect(() => {
        const getIntegrations = async () => {
            setLoading(true)
            try {
                const resp = await getCustomerAuthIntegration(customer.id)
                if (isAPIError(resp)) {
                    notification.error({
                        message: `Could not retrieve integration: ${resp.description}`,
                        duration: 10,
                    });
                    return;
                }
                if (resp.id) {
                    setConfiguredIntegrations([resp]);
                    setHasAuthIntegration(true);
                }
            } finally {
                setLoading(false)
            }
        };
        const authIntegrationTypes = async () => {
            const resp = await getAuthIntegrationTypes();
            if (isAPIError(resp)) {
                notification.error({
                    message: `Could not retrieve integration types: ${resp.description}`,
                    duration: 10,
                });
                return;
            }
            setAuthIntTypes(resp);
        }
        getIntegrations();
        authIntegrationTypes();
    }, [customer]);

    const handleAuthIntModalCancel = () => {
        setIsAuthIntModalOpen(false);
        createAuthIntForm.resetFields();
        setAuthIntMetadataFields([]);
    }

    const populateAuthIntMetadataFields = (type: string) => {
        const properties = authIntTypes[type].properties
        const required = authIntTypes[type].required

        const boolRadio = (
            <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
            </Radio.Group>
        )

        const fields = Object.keys(properties).map((property: string) => {
            return (
                <Form.Item
                    label={properties[property].title}
                    key={property}
                    name={property}
                    rules={[
                        {
                            required: required.includes(property),
                            message: "This field is required",
                        },
                    ]}
                >
                    {properties[property].type === 'boolean' ? boolRadio : <Input />}
                </Form.Item>
            )
        });

        setAuthIntMetadataFields(fields);
    }

    const handleAuthIntModalOk = async () => {
        const values = await createAuthIntForm.validateFields();
        const {auth_int_type, ...metadata} = values
        const name = `${auth_int_type}-${customer.id}`;

        try {
            const resp = await createAuthIntegration(customer.id, auth_int_type, name, metadata);
            if (isAPIError(resp)) {
                notification.error({
                    message: `Could not create integration: ${resp.description}`,
                    duration: 10,
                });
                return;
            }
            setConfiguredIntegrations([resp, ...configuredIntegrations]);
        } finally {
            handleAuthIntModalCancel();
        }

    }

    const renderAuthIntegrationCreateModal = () => {
        return (
            <Modal
                okText="Go"
                title="Create Auth Integration"
                open={isAuthIntModalOpen}
                onOk={handleAuthIntModalOk}
                onCancel={handleAuthIntModalCancel}
            >
                <Form form={createAuthIntForm}>
                    <Form.Item
                        label="Type"
                        name="auth_int_type"
                        rules={[
                            { required: true, message: "This field is required" },
                        ]}
                    >
                        <Select
                            onClear={() => setAuthIntMetadataFields([])}
                            onDeselect={() => setAuthIntMetadataFields([])}
                            onSelect={(type) => {populateAuthIntMetadataFields(type)}}
                        >
                            {Object.keys(authIntTypes).map((type) => (
                                <Select.Option key={type} value={type}>{type}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {authIntMetadataFields}
                </Form>
            </Modal>
        );
    }

    return (
        <div>
            {renderAuthIntegrationCreateModal()}
            <Typography.Title level={3}>Configured Integrations</Typography.Title>
            <Spin spinning={loading}>
                <Table dataSource={loading ? [] : configuredIntegrations}>
                    <Column title="Integration ID" dataIndex="id" key="id" />
                    <Column title="Identity Type" dataIndex="identityType" key="identityType" />
                    <Column title="Name" dataIndex="name"/>
                    <Column
                        title="Metadata"
                        dataIndex="integrationMetadata"
                        key="integrationMetadata"
                        render={val => (<pre>{JSON.stringify(val)}</pre>)}
                    />
                </Table>
            </Spin>
            <Button
                key="create-auth-integration"
                type="primary"
                disabled={hasAuthIntegration}
                onClick={() => setIsAuthIntModalOpen(true)}
                style={{ marginTop: 16, marginBottom: 10}}
            >Add Auth Integration</Button>
            <p><Typography.Text type="secondary">
                Note: After you create an Auth Integration here, additional setup is required to complete configuration. See the <a href="https://www.notion.so/spiralup/Configure-an-Okta-integration-8b5560670cd84fe6b5c1fc5ce3085bd2">documentation</a> for more information.
            </Typography.Text></p>
        </div>
    )
};

export default IntegrationsPage;