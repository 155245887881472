/* eslint-disable */
// This file was initially automatically generated by Amplify, but is now updated manually as needed

const awsmobile = {
	"aws_project_region": "us-east-1",
	"aws_cognito_region": "us-east-1",
	"aws_user_pools_id": "us-east-1_uea6ZnRxn",
	"aws_user_pools_web_client_id": "1qu9vbb6hs3hrr19o2akf6c0k6",
	"oauth": {
		"domain": "spiral.auth.us-east-1.amazoncognito.com",
		"scope": [
			"phone",
			"email",
			"openid",
			"profile",
			"aws.cognito.signin.user.admin"
		],
		"redirectSignIn": `${window.location.origin}/dashboards`,
		"redirectSignOut": `${window.location.origin}`,
		"responseType": "code"
	},
	"federationTarget": "COGNITO_USER_POOLS",
	"aws_cognito_username_attributes": [
	    "EMAIL"
	],
	"aws_cognito_social_providers": [],
	"aws_cognito_signup_attributes": [
	    "EMAIL"
	],
	"aws_cognito_mfa_configuration": "OFF",
	"aws_cognito_mfa_types": [
	    "SMS"
	],
	"aws_cognito_password_protection_settings": {
	    "passwordPolicyMinLength": 8,
	    "passwordPolicyCharacters": []
	},
	"aws_cognito_verification_mechanisms": [
	    "EMAIL"
	],
	"aws_user_files_s3_bucket": "dashboard-app0eaf6e104a4b4a61bf7e1376bd203d41prod-prod",
	"aws_user_files_s3_bucket_region": "us-east-1"
    };

    export default awsmobile;