import { Form, Input, Modal, notification } from "antd";
import { Auth } from "aws-amplify";
import React from "react";

import { isAPIError } from "../indexTypes";
import { getAuthIntegrationByDomain } from "../reportApi";

export const OktaAuthModal = ({
  isModalOpen,
  setIsModalOpen,
  setShowAlert,
}: {
  isModalOpen: boolean,
  setIsModalOpen: (isOpen:boolean) => void,
  setShowAlert: (isOpen:boolean) => void
}) => {
  const [ssoForm] = Form.useForm();

  const handleOk = async () => {
    const values = await ssoForm.validateFields();
    const email = values.email;
    const domain = email.split("@")[1];

    try {
      const resp = await getAuthIntegrationByDomain(domain);
      if (isAPIError(resp)) {
        notification.error({
          message: `Could not retrieve integration from provided domain: ${resp.description}`,
          duration: 10,
        });
        return;
      }
      const idpName = resp.name
      if (idpName) {
        await Auth.federatedSignIn({customProvider: idpName})
      } else {
        setShowAlert(true);
      }
    } finally {
      ssoForm.resetFields();
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    ssoForm.resetFields();
    setIsModalOpen(false);
  };

  type FieldType = {
    email?: string;
  };

  return (
    <Modal
      okText="Go"
      title="Sign in with Okta"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form={ssoForm}
      >
        <Form.Item<FieldType>
          label="Email"
          name="email"
          rules={[
            { required: true, message: "This field is required" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
