import React from "react";
import { Navigate, useParams } from "react-router";
import { ReportsViewerWrapper } from "../components/ReportsViewer";
import { isAdmin, useCurrentUser } from "../hooks";
import { ReportAPIResp } from "../indexTypes";
import { isDefined } from "../utils";

export const MainPage = ({
  reports,
  defaultReportSet,
}: {
  reports?: ReportAPIResp;
  reportId?: string;
  defaultReportSet?: string;
}) => {
  const params = useParams();
  const user = useCurrentUser();
  const userIsAdmin = isAdmin(user);

  const blank = <div>Under Construction 🛠️</div>;

  if (!isDefined(reports)) return blank;
  if (!params.reportId) return blank;
  if (!isDefined(reports[params.reportId])) {
    if (isDefined(defaultReportSet) && isDefined(reports[defaultReportSet])) {
      return <Navigate to={`/dashboards`} replace />;
    } else {
      return blank;
    }
  }

  return <ReportsViewerWrapper report={reports[params.reportId]} admin={userIsAdmin} />;
};
