import { endpoint, fetchWithReject, headers } from "./utils";

export const getTimeseries = async (
  customer: string,
  startDate: string,
  endDate: string,
  granularity: string,
  groupBy?: string
) => {
  const params = new URLSearchParams({ customer, startDate, endDate, granularity });
  if (groupBy) {
    params.append("groupBy", groupBy);
  }
  return fetchWithReject(`${endpoint}/v2/timeseries?` + params, {
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export const getExamples = async (
  customer: string,
  startDate: string,
  endDate: string,
  tags?: { [key: string]: string | null },
  metadata?: string[]
) => {
  const params = new URLSearchParams({ customer, startDate, endDate });
  if (tags) {
    params.append("tags", JSON.stringify(tags));
  }
  if (metadata) {
    params.append("metadata", metadata.join(","));
  }
  return fetchWithReject(`${endpoint}/v2/examples?` + params, {
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export const getEWS = async (
  customer: string,
  startDate: string,
  endDate: string,
  period: number,
  tags: string
) => {
  const params = new URLSearchParams({
    customer,
    startDate,
    endDate,
    period: period.toString(),
    tags,
  });
  return fetchWithReject(`${endpoint}/v2/ews?` + params, {
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};
