import { Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router";
import { getConversationDebug } from "../../reportApi";

const { Title } = Typography;

import { stripPii } from "../../utils";
import "./ConversationDebugPage.less";

interface Message {
  message: string;
  type: string;
  user_type: string;
  id?: string;
  timestamp?: string;
  title?: string;
  author?: string;
  is_agent?: boolean;
  relative_time?: string;
}

interface Conversation {
  conversationMetadata: object;
  messages: Message[];
  customer: string;
  language: string;
  datasetId: number;
  createdAt: string;
  updatedAt: string;
  sourceFile: string;
}

interface Evidence {
  id: number;
  conversation_id: string;
  created_at: string;
  text: string;
  sentiment: number;
  category?: string;
  cluster_id: number;
  cluster_title: string;
  cluster_default_title: string;
}

interface ConversationDebug {
  conversation: Conversation;
  evidences: Evidence[];
}

export const ConversationDebugPage = () => {
  const { conversationId } = useParams();

  const [data, setData] = useState<ConversationDebug>();
  useEffect(() => {
    const f = async () => {
      if (conversationId !== undefined) {
        setData(await getConversationDebug(conversationId));
      }
    };
    f();
  }, [conversationId]);

  if (!data) {
    return <Spin spinning />;
  }

  return (
    <div className="container">
      <Title level={3}>Conversation Debug for #{conversationId}</Title>
      <div className="flex-wrapper">
        <div className="column" style={{ width: "300px" }}>
          <Title level={5}>Conversation</Title>
          <div className="messages">
            {data.conversation.messages.map(msg => {
              const userType =
                msg.user_type == "customer"
                  ? "customer"
                  : msg.is_agent
                  ? "agent"
                  : msg.user_type == "system" && msg.message.length > 60
                  ? "agent"
                  : msg.user_type == "system"
                  ? "system"
                  : "agent";
              return (
                <div key={msg.message} className={"message " + userType}>
                  {stripPii(msg.message)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="column selectable" style={{ width: "70%" }}>
          <Title level={5}>Evidences</Title>
          <table style={{ width: "100%", tableLayout: "fixed", border: "1px solid" }}>
            <colgroup>
              <col style={{ width: "60%" }} />
              <col style={{ width: "40%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Text</th>
                <th>Intent</th>
              </tr>
            </thead>
            <tbody>
              {data.evidences.map(e => {
                return (
                  <tr key={e.id}>
                    <td>{e.text}</td>
                    <td>
                      {e.cluster_id
                        ? `${
                            e.cluster_title ? e.cluster_title ?? e.cluster_title : "[No Title]"
                          } (${e.cluster_id})`
                        : e.category
                        ? e.category
                        : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* <div className="column">RFC</div> */}
      </div>
    </div>
  );
};
