import React, { useEffect, useState } from "react";

import { Col, Row, Select, Spin, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import { NavLink } from "react-router-dom";
import { User } from "../../indexTypes";
import { getUsers } from "../../reportApi";

export const UsersPage = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const f = async () => {
      setLoading(true);
      try {
        setUsers((await getUsers()).users);
      } finally {
        setLoading(false);
      }
    };
    f();
  }, []);

  return (
    <Row className="selectable" key="users" style={{ margin: "0 24px 12px" }}>
      <Col>
        <Spin spinning={loading}>
          <div>{users.length} Users</div>
          <Table dataSource={loading ? [] : users} pagination={false}>
            <Column title="User ID" dataIndex="id" />
            <Column
              title="Username"
              dataIndex="username"
              sorter={(a: User, b: User) => a.username.localeCompare(b.username)}
              filters={users.map(u => ({ text: u.username, value: u.id }))}
              onFilter={(value, record) => (record as User).id === value}
              filterSearch={true}
              filterDropdown={({ selectedKeys, setSelectedKeys, confirm }) => {
                const options = users.map(u => ({ label: u.username, value: u.id }));
                return (
                  <Select
                    allowClear
                    onClear={() => {
                      setSelectedKeys([]);
                      confirm();
                    }}
                    style={{ width: 230 }}
                    placeholder="Select"
                    onChange={e => {
                      if (e) {
                        setSelectedKeys([e]);
                        confirm();
                      }
                    }}
                    options={options}
                    value={selectedKeys[0]}
                    optionFilterProp="label"
                    showSearch
                  />
                );
              }}
            />
            <Column title="Cognito ID" dataIndex="cognitoSub" />
            <Column
              title="Created"
              dataIndex="createdAt"
              render={val => val.toLocaleString()}
              sorter={(a: User, b: User) =>
                new Date(a.createdAt).getUTCMilliseconds() -
                new Date(b.createdAt).getUTCMilliseconds()
              }
              defaultSortOrder="descend"
            />
            <Column
              title="Customers"
              dataIndex="customers"
              render={(customers: string[]) => (
                <>
                  {customers.map(c => (
                    <Tag key={c}>{c}</Tag>
                  ))}
                </>
              )}
            />
            <Column
              title="Edit"
              render={user => <NavLink to={{ pathname: user.cognitoSub }}>Edit</NavLink>}
            />
          </Table>
        </Spin>
      </Col>
    </Row>
  );
};
