import { Tooltip } from "antd";
import React from "react";

const ModeTooltip = (props: any) => {
    if (!props.mode) return null;
    const modeTooltipContent = (
        <div>
            <h3>{props.mode.name}</h3>
            <p>{props.mode.explain}</p>
        </div>
    );
    return (
        <Tooltip title={modeTooltipContent}>
            &nbsp;<span>{props.mode.name}</span>&nbsp;
        </Tooltip>
    );
};
export default ModeTooltip;