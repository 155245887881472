import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip as AntTooltip, Button, Space } from "antd";
import React from "react";

import { TimeseriesMetadata } from "../indexTypes";
import { formatPercent } from "../utils";
import { TimeseriesChart } from "./TimeseriesChart";

interface TaxonomyNodeHeaderProps {
  taxonomyNodeId: string;
  name: string;
  data: TimeseriesMetadata;
  total: number;
  mean: number;
  granularity: string;
  scale: number;
  percent: number;
  parentId: string | undefined;
  nodeChildren: string[];
}

export const TaxonomyNodeHeader: React.FC<TaxonomyNodeHeaderProps> = ({
  taxonomyNodeId,
  name,
  data,
  total,
  mean,
  percent,
  granularity,
  scale,
  parentId = undefined,
  nodeChildren = [],
}) => {
  return (
    <Space size={30} key={taxonomyNodeId}>
      <span>{name}</span>
      <div className="dot" />
      <div className="field">
        <AntTooltip title={() => <span>Percent of total contacts over the whole period</span>}>
          <div className="field-desc">
            Percent <InfoCircleOutlined />
          </div>
          {percent && formatPercent(percent)}
        </AntTooltip>
      </div>
      <div className="dot" />
      <div className="field">
        <AntTooltip
          title={() => <span>Total number of contacts in this group over the whole period</span>}
        >
          <div className="field-desc">
            Volume <InfoCircleOutlined />
          </div>
          <span>{total && total.toLocaleString()}</span>
        </AntTooltip>
      </div>
      <div className="dot" />
      <div className="field">
        <AntTooltip
          title={() => (
            <span>
              Average number of contacts in this group per {granularity} over the whole period
            </span>
          )}
        >
          <div className="field-desc">
            Avg Rate <InfoCircleOutlined />
          </div>
          <span>
            {mean && Math.round(mean).toLocaleString()}
            <small>/{granularity}</small>
          </span>
        </AntTooltip>
      </div>
      <div className="dot" />
      <TimeseriesChart
        metadata={data}
        graphheight={55}
        graphwidth={132}
        range={parentId && scale ? [0, scale] : undefined}
        showEmpty={false}
      />
      <div className="highlight" />
      <Space size={19} className="tags">
        {nodeChildren.length > 0 && (
          <Button key={`{nodeChildren.length} Sub Issues`} type="primary">
            {nodeChildren.length} Sub Issue{nodeChildren.length > 1 ? "s" : ""}
          </Button>
        )}
      </Space>
    </Space>
  );
};
