import React, { useEffect, useState } from "react";

import {
  Tooltip as AntTooltip,
  Button,
  Card,
  DatePicker,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Typography,
  notification,
} from "antd";
import Title from "antd/lib/typography/Title";
import moment, { Moment } from "moment";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCustomer } from "../hooks";
import { getReportingData } from "../reportApi";
import { ReportingDataResp, SuperclusterData, SuperclusterDatum } from "../reports";

import { ReloadOutlined, SelectOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { Navigate, useParams } from "react-router";
import { Filter, ReportAPIResp, isAPIError } from "../indexTypes";
import { buildShareUrl, displayName, isDefined, truncate } from "../utils";
import "./ReportingPage.less";

type DisplayMode = {
  columnLabel: string;
  unitLabel: string;
  yAxisDomain?: [number | string, number | string];
};

const CountDisplayMode: DisplayMode = {
  columnLabel: "Count",
  unitLabel: "",
};

const AverageDisplayMode: DisplayMode = {
  columnLabel: "Average",
  unitLabel: "",
  yAxisDomain: [0, "dataMax"],
};

const PercentageDisplayMode: DisplayMode = {
  columnLabel: "%",
  unitLabel: "%",
  yAxisDomain: [0, 100],
};

export const ReportingPage = ({ reports }: { reports?: ReportAPIResp }) => {
  // Handle re-routing if either no reports or a valid report ID are provided
  const params = useParams();
  const blank = <div>Under Construction 🛠️</div>;
  if (!isDefined(reports)) return blank;
  if (!isDefined(params.reportId)) {
    console.error("No report ID provided");
    return <Navigate to={`/dashboards`} replace />;
  }
  const report = reports[params.reportId];
  if (!isDefined(report)) {
    // || !isDefined(report.reportIndexJson.reportingConfig)) {
    console.error(`Report with ID "${params.reportId}" not setup for reporting view`);
    return <Navigate to={`/dashboards`} replace />;
  }

  const { customer } = useCustomer();
  type ReportingPeriodOption = "week" | "month" | "quarter";
  const defaultReportingPeriod: ReportingPeriodOption = "month";
  const endDate = report.reportIndexJson.endDate;
  const defaultStartMoment = moment(endDate ?? undefined)
    .subtract(1, defaultReportingPeriod)
    .startOf(defaultReportingPeriod);
  const [startDate, setStartDate] = useState<Moment>(defaultStartMoment);
  const [reportingPeriod, setReportingPeriod] =
    useState<ReportingPeriodOption>(defaultReportingPeriod);
  const setReportingPeriodAndAdjustStartDate = (value: ReportingPeriodOption) => {
    // Adjust the start date to the beginning of the currently selected period
    const newStartDate = moment(startDate).startOf(value);
    setStartDate(newStartDate);
    setReportingPeriod(value);
  };

  const [hideLowVolumeIssues, setHideLowVolumeIssues] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [activeLineKey, setActiveLineKey] = useState<string>();

  const [resp, setResp] = useState<ReportingDataResp>();
  const [dayCountsBySupercluster, setDayCountsBySupercluster] = useState<SuperclusterData>();
  type SuperclusterPeriodAggData = {
    [scId: string]: {
      superclusterTitle: string;
      currentPeriodAgg: number;
      previousPeriodAgg: number;
    };
  };
  const [superclusterAggTotal, setSuperclusterAggTotal] = useState<SuperclusterPeriodAggData>();
  const [filters, setFilters] = useState<{ [field: string]: string[] | undefined }>({});
  const [filterField, setFilterField] = useState<string>();
  const [filterValue, setFilterValue] = useState<string[]>();
  const [discreteFilters, setDiscreteFilters] = useState<{
    [field: string]: string[] | undefined;
  }>({});

  /*
  - If we have just an aggregate key, display as an average
  - If we have both an aggregate key and value, display as a percentage
  - If we have neither, display as a raw conversation count
  */
  const aggregateMetadataKey = report.reportIndexJson.reportingConfig?.aggregateMetadataKey;
  const aggregateMetadataValue = report.reportIndexJson.reportingConfig?.aggregateMetadataValue;
  const chartTitle = report.reportIndexJson.reportingConfig?.chartTitle;
  const xAxisLabel = report.reportIndexJson.reportingConfig?.xAxisLabel;
  const yAxisLabel = report.reportIndexJson.reportingConfig?.yAxisLabel;

  let reportDisplayMode: DisplayMode = CountDisplayMode;
  if (aggregateMetadataKey) {
    reportDisplayMode = aggregateMetadataValue ? PercentageDisplayMode : AverageDisplayMode;
  }

  const getReportingPeriodInDays = (reportingPeriod: ReportingPeriodOption) => {
    switch (reportingPeriod) {
      case "week":
        return 7;
      case "month":
        return 30;
      case "quarter":
        return 90;
    }
  };

  // Disable a date on the picker if it's in the future or less than one whole period in the future
  const disabledDates = (current: Moment) => {
    return (
      (endDate && current > moment(endDate)) ||
      current > moment().endOf("day") ||
      (current >= moment().startOf(reportingPeriod) &&
        current < defaultStartMoment.add(1, reportingPeriod))
    );
  };

  const invokeGetReportingData = async (
    customerId: string,
    reportId: string,
    startDate: Moment,
    reportingPeriod: ReportingPeriodOption
  ) => {
    const base: Filter[] = [];
    const reportingData = await getReportingData(
      customerId,
      reportId,
      startDate.toISOString(),
      getReportingPeriodInDays(reportingPeriod),
      base.concat(
        Object.entries(filters)
          .filter(([, v]) => isDefined(v))
          .filter(([, v]) => v!.length > 0)
          .map(([k, v]) => ({ field: k, values: v! }))
      )
    );
    if (isAPIError(reportingData)) {
      console.error(
        `Error fetching data for start date "${startDate}" and reporting period "${reportingPeriod}"`,
        reportingData
      );
      throw new Error(reportingData.description);
    }
    setResp(reportingData);
  };

  const transformReportingDataForDisplay = () => {
    if (!resp || !resp.data || !resp.prev_data || !resp.title_mapping) return;

    // Below we're calculating the total number of conversations for each supercluster in the current and previous periods
    const superclusterData: SuperclusterData = resp.data;
    const prevSuperclusterData: SuperclusterData = resp.prev_data;
    const scAggTotals = superclusterData.reduce((acc, curr) => {
      Object.keys(curr).forEach(key => {
        if (key !== "date") {
          if (!acc[key]) {
            acc[key] = {
              superclusterTitle: resp.title_mapping[key],
              currentPeriodAgg: 0,
              previousPeriodAgg: 0,
            };
          }
          acc[key].currentPeriodAgg += curr[key];
        }
      });
      return acc;
    }, {} as SuperclusterPeriodAggData);
    prevSuperclusterData.forEach(prevData => {
      Object.keys(prevData).forEach(key => {
        if (key !== "date") {
          if (scAggTotals[key]) {
            scAggTotals[key].previousPeriodAgg += prevData[key];
          }
        }
      });
    });
    const highVolumeSuperclusterIds = Object.keys(scAggTotals)
      .sort(
        (a: string, b: string) => scAggTotals[b].currentPeriodAgg - scAggTotals[a].currentPeriodAgg
      )
      .slice(0, 10);

    // If we're displaying an aggregated metric, compute an average for each supercluster over the current period
    if (aggregateMetadataKey) {
      Object.keys(scAggTotals).forEach(key => {
        if (scAggTotals[key]) {
          scAggTotals[key].currentPeriodAgg =
            scAggTotals[key].currentPeriodAgg / superclusterData.length;
          scAggTotals[key].previousPeriodAgg =
            scAggTotals[key].previousPeriodAgg / prevSuperclusterData.length;
        }
      });
    }
    // Filter out low-volume superclusters for the current period
    if (hideLowVolumeIssues && reportDisplayMode === CountDisplayMode) {
      setSuperclusterAggTotal(
        Object.keys(scAggTotals)
          .filter(key => highVolumeSuperclusterIds.includes(key))
          .reduce((acc: SuperclusterPeriodAggData, key: string) => {
            acc[key] = scAggTotals[key];
            return acc;
          }, {} as SuperclusterPeriodAggData)
      );
      setDayCountsBySupercluster(
        superclusterData.map(datum => {
          return Object.keys(datum)
            .filter(key => highVolumeSuperclusterIds.includes(key))
            .reduce((acc: SuperclusterDatum, key: string) => {
              acc[key] = datum[key];
              return acc;
            }, {} as SuperclusterDatum);
        })
      );
    } else {
      setSuperclusterAggTotal(scAggTotals);
      setDayCountsBySupercluster(superclusterData);
    }
  };

  const retrieveReportingData = () => {
    setIsLoading(true);
    setDayCountsBySupercluster(undefined);
    setSuperclusterAggTotal(undefined);
    invokeGetReportingData(customer.id, params.reportId!, startDate, reportingPeriod)
      .catch(() => {
        notification.error({
          message: "Error fetching data",
          duration: 5,
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (customer) {
      setStartDate(defaultStartMoment);
      setReportingPeriod(defaultReportingPeriod);
      retrieveReportingData();
    }
  }, [params.reportId]);

  useEffect(() => {
    if (resp && resp.data.length === 0) {
      notification.warning({
        message: "No data available for the selected period",
        duration: 5,
      });
    } else {
      transformReportingDataForDisplay();
    }
  }, [resp, hideLowVolumeIssues]);

  useEffect(() => {
    if (filterField && filterValue) {
      setFilters({ ...discreteFilters, ...{ [filterField]: filterValue } });
    } else {
      setFilters({ ...discreteFilters });
    }
  }, [discreteFilters, filterField, filterValue]);

  const formatNumericValue = (value: number): string => {
    return !Number.isInteger(value) ? (value as number).toFixed(2) : value.toString();
  };

  const GraphPointLabel = (props: any) => {
    const { x, y, stroke, value, index } = props;

    return (
      <text
        x={x}
        y={y}
        dy={index % 2 !== 0 || value === 0 ? -6 : 12}
        fill={stroke}
        fontSize={12}
        textAnchor="middle"
        style={{ textShadow: "1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff" }}
      >
        {formatNumericValue(value)}
      </text>
    );
  };

  const GraphTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length && activeLineKey) {
      const matchingPayloadItem = payload.find((item: any) => item.dataKey === activeLineKey);
      return (
        <div className="reporting-graph-tooltip">
          <p className="label">{`${matchingPayloadItem.name}`}</p>
        </div>
      );
    }

    return null;
  };

  const lineColors = ["#465fc3", "#ff4e4e", "#43b139", "#faa263", "#ffbf00"];

  return (
    <div style={{ margin: "24px" }} id="reporting-view">
      <Title level={3}>{displayName(report.name)} Reporting View</Title>
      <Spin spinning={isLoading}>
        <>
          <Space style={{ marginBottom: 10 }}>
            <label htmlFor="periodPicker">
              <Typography.Text strong>Reporting Period:</Typography.Text>
            </label>
            <Select
              id="periodPicker"
              placeholder="Reporting period"
              disabled={isLoading}
              value={reportingPeriod}
              onChange={value => {
                setReportingPeriodAndAdjustStartDate(value);
                setIsFormChanged(true);
              }}
            >
              <Select.Option value="week">Week</Select.Option>
              <Select.Option value="month">Month</Select.Option>
              <Select.Option value="quarter">Quarter</Select.Option>
            </Select>
            <label htmlFor="datePicker">
              <Typography.Text strong>Report Start Date:</Typography.Text>
            </label>
            <DatePicker
              id="datePicker"
              disabled={isLoading}
              disabledDate={disabledDates}
              picker={reportingPeriod}
              format={"YYYY-MM-DD"}
              onChange={date => {
                if (date) {
                  setStartDate(date);
                  setIsFormChanged(true);
                }
              }}
              defaultValue={startDate}
              value={startDate}
              className="rounded"
            />
            {report.reportIndexJson.reportingConfig?.enableFiltering &&
              report.reportIndexJson.filters &&
              Object.entries(report.reportIndexJson.filters).filter(
                ([, field]) => field.discrete === false
              ).length > 0 && (
                <Space>
                  <Typography.Text strong>Filter By:</Typography.Text>
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    showSearch
                    value={filterField}
                    style={{ width: 140 }}
                    allowClear
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={value => {
                      setFilterField(value === "" ? undefined : value);
                      setFilterValue(undefined);
                      setIsFormChanged(true);
                    }}
                    dropdownMatchSelectWidth={false}
                    placeholder="Choose Filter"
                  >
                    {filterField && (
                      <Select.Option key="blank" value="">
                        {" "}
                      </Select.Option>
                    )}
                    {Object.entries(report.reportIndexJson.filters)
                      .filter(([, field]) => field.discrete === false)
                      .map(([fieldId, field]) => (
                        <Select.Option key={fieldId} value={fieldId}>
                          {field.displayName ??
                            displayName(fieldId, report.reportIndexJson.displayNames)}
                        </Select.Option>
                      ))}
                  </Select>
                  {filterField && (
                    <Select
                      getPopupContainer={(triggerNode: HTMLElement) =>
                        triggerNode.parentNode as HTMLElement
                      }
                      showSearch
                      value={filterValue}
                      style={{ width: 140, paddingRight: 10, zIndex: 9999 }}
                      allowClear={false}
                      filterOption={(input, option) =>
                        isDefined(option) && option.key.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={value => {
                        setFilterValue(value);
                        setIsFormChanged(true);
                      }}
                      dropdownMatchSelectWidth={false}
                      mode="multiple"
                      placeholder="Choose Value"
                    >
                      {filterField &&
                        filterField in report.reportIndexJson.filters &&
                        Object.entries(report.reportIndexJson.filters[filterField].values).map(
                          ([valueId, value]) => (
                            <Select.Option key={valueId} value={valueId}>
                              {truncate(
                                value.displayName ??
                                  displayName(valueId, report.reportIndexJson.displayNames),
                                60
                              )}
                            </Select.Option>
                          )
                        )}
                    </Select>
                  )}
                </Space>
              )}
            {report.reportIndexJson.filters &&
              Object.entries(report.reportIndexJson.filters)
                .filter(([, field]) => field.discrete !== false)
                .map(([fieldId, field]) => (
                  <Space key={fieldId} size="middle">
                    <span>{displayName(fieldId, report.reportIndexJson.displayNames)}:</span>
                    <Select
                      value={discreteFilters[fieldId]}
                      style={{ width: 240 }}
                      allowClear={!field.required}
                      onChange={value => {
                        setDiscreteFilters({ ...discreteFilters, ...{ [fieldId]: value } });
                        setIsFormChanged(true);
                      }}
                      dropdownMatchSelectWidth={false}
                      mode="multiple"
                    >
                      {Object.entries(field.values).map(([valueId, value]) => (
                        <Select.Option key={valueId} value={valueId}>
                          {value.displayName ??
                            displayName(valueId, report.reportIndexJson.displayNames)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Space>
                ))}
            {reportDisplayMode === CountDisplayMode && (
              <AntTooltip title="Filters issue categories on graph and table to the top 10 by contact volume">
                <Space>
                  <label htmlFor="hideLowVolumeIssues">
                    <Typography.Text strong>Hide Low Volume Issues:</Typography.Text>
                  </label>
                  <Switch
                    id="hideLowVolumeIssues"
                    checked={hideLowVolumeIssues}
                    onChange={checked => {
                      setHideLowVolumeIssues(checked);
                    }}
                  />
                </Space>
              </AntTooltip>
            )}
            <Button
              type="primary"
              disabled={isLoading || !isFormChanged}
              onClick={retrieveReportingData}
            >
              <ReloadOutlined />
              Update
            </Button>
          </Space>
          {resp && dayCountsBySupercluster && superclusterAggTotal && (
            <>
              <div style={{ width: "100%", marginBottom: 10 }}>
                <Card>
                  <div className="reporting-graph-card">
                    <ResponsiveContainer>
                      <LineChart data={dayCountsBySupercluster}>
                        <XAxis dataKey="date" axisLine={false} tickLine={false} height={50}>
                          <Label value={xAxisLabel} position="insideBottom" />
                          <Label value={chartTitle} position="bottom" />
                        </XAxis>
                        <YAxis
                          axisLine={false}
                          tickLine={false}
                          domain={reportDisplayMode.yAxisDomain}
                        >
                          <Label value={yAxisLabel} position="left" angle={-90} style={{ textAnchor: "middle" }}/>
                        </YAxis>
                        <Tooltip content={<GraphTooltip />} />
                        <CartesianGrid stroke="rgba(165,170,191,0.5)" strokeDasharray="2 7" />
                        {Object.keys(dayCountsBySupercluster[0])
                          .filter(key => key !== "date")
                          .sort((a, b) =>
                            resp.title_mapping[a].localeCompare(resp.title_mapping[b])
                          )
                          .map((key, index) => {
                            return (
                              <Line
                                key={key}
                                type="linear"
                                label={key === activeLineKey ? <GraphPointLabel /> : undefined}
                                dot={false}
                                activeDot={true}
                                dataKey={key}
                                stroke={
                                  !activeLineKey || key === activeLineKey
                                    ? lineColors[index % lineColors.length]
                                    : `${lineColors[index % lineColors.length]}15`
                                }
                                strokeWidth={2}
                                onMouseOver={() => setActiveLineKey(key)}
                                onMouseLeave={() => setActiveLineKey(undefined)}
                                name={resp.title_mapping[key]}
                              />
                            );
                          })}
                        <Legend
                          layout="vertical"
                          align="right"
                          onMouseEnter={item => setActiveLineKey(`${item.dataKey}`)}
                          onMouseLeave={() => setActiveLineKey(undefined)}
                          wrapperStyle={{
                            height: "95%",
                            width: "20%",
                            overflow: "auto",
                            paddingLeft: "5%",
                          }}
                          formatter={(value, entry) => {
                            return <span style={{ color: entry.color }}>{value}</span>;
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </Card>
              </div>
              <div className="reporting-issue-counts-table">
                <Table
                  className="reporting-counts-table"
                  dataSource={Object.values(superclusterAggTotal)}
                  pagination={false}
                  size="small"
                >
                  <Column
                    title="Category"
                    dataIndex="superclusterTitle"
                    key="superclusterTitle"
                    defaultSortOrder={"ascend"}
                    sorter={(a: any, b: any) =>
                      a.superclusterTitle.localeCompare(b.superclusterTitle)
                    }
                    render={(text: string) => (
                      <Typography.Link
                        href={buildShareUrl(
                          params.reportId!,
                          undefined,
                          Object.keys(superclusterAggTotal).find(
                            sc => superclusterAggTotal[sc].superclusterTitle === text
                          ),
                          text.split(" / ").length > 1 ? text.split(" / ")[0] : undefined
                        )}
                        target="_blank"
                      >
                        <Space size="small">
                          <span>{text}</span>
                          <SelectOutlined rotate={90} />
                        </Space>
                      </Typography.Link>
                    )}
                  />
                  <Column
                    title={`Current Period ${reportDisplayMode.columnLabel}`}
                    dataIndex="currentPeriodAgg"
                    key="currentPeriodAgg"
                    align="right"
                    render={(text: string) =>
                      `${formatNumericValue(parseFloat(text))}${reportDisplayMode.unitLabel}`
                    }
                    sorter={(a: any, b: any) => a.currentPeriodAgg - b.currentPeriodAgg}
                  />
                  <Column
                    title={`Previous Period ${reportDisplayMode.columnLabel}`}
                    dataIndex="previousPeriodAgg"
                    key="previousPeriodAgg"
                    align="right"
                    render={(text: string) =>
                      `${formatNumericValue(parseFloat(text))}${reportDisplayMode.unitLabel}`
                    }
                    sorter={(a: any, b: any) => a.previousPeriodAgg - b.previousPeriodAgg}
                  />
                  <Column
                    title="Change"
                    dataIndex="change"
                    key="change"
                    align="right"
                    render={(_: string, record: any) => {
                      const change = record.currentPeriodAgg - record.previousPeriodAgg;
                      if (reportDisplayMode === AverageDisplayMode) {
                        return <Typography.Text>{formatNumericValue(change)}</Typography.Text>;
                      } else if (reportDisplayMode === PercentageDisplayMode) {
                        return <Typography.Text>{formatNumericValue(change)}%</Typography.Text>;
                      } else {
                        return change > 0 ? (
                          <Typography.Text type="danger">
                            +{formatNumericValue(change)}
                          </Typography.Text>
                        ) : (
                          <Typography.Text type="success">
                            {formatNumericValue(change)}
                          </Typography.Text>
                        );
                      }
                    }}
                    sorter={(a: any, b: any) =>
                      a.currentPeriodAgg -
                      a.previousPeriodAgg -
                      (b.currentPeriodAgg - b.previousPeriodAgg)
                    }
                  />
                </Table>
              </div>
            </>
          )}
        </>
      </Spin>
    </div>
  );
};
