import { Empty } from "antd";
import moment from "moment";
import React from "react";
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import { CurveType } from "recharts/types/shape/Curve";

import { TimeseriesMetadata } from "../indexTypes";

const graphBlue = "#465FC3";
const graphRed = "#FF4E4E";
const graphGreen = "#43B139";


export const TimeseriesChart = ({
    metadata,
    secondaryMetadata,
    graphheight,
    graphwidth,
    yaxis,
    xaxis,
    range,
    className,
    color,
    grid,
    animationDuration = 300,
    dot = false,
    average,
    percent = true,
    xTickFormat = "D MMM 'YY",
    preserve = "preserveStart",
    legendText,
    showTooltip = false,
    seriesName = "Conversations",
    minTickGap,
    areaType,
    showEmpty = true,
  }: {
    metadata: TimeseriesMetadata | undefined;
    secondaryMetadata?: TimeseriesMetadata;
    graphheight: number;
    graphwidth?: number;
    yaxis?: boolean;
    xaxis?: boolean;
    range?: Array<number>;
    className?: string;
    color?: string;
    grid?: boolean;
    animationDuration?: number;
    dot?: boolean;
    daysToChart?: number;
    average?: number;
    percent?: boolean;
    xTickFormat?: string;
    preserve?: "preserveStart" | "preserveEnd";
    legendText?: string;
    showTooltip?: boolean;
    seriesName?: string;
    minTickGap?: number;
    areaType?: CurveType;
    showEmpty?: boolean;
  }) => {
    let graphColor;
    let transparency;
    // eslint-disable-next-line id-blacklist
    if (color === undefined) {
        graphColor = graphBlue;
        transparency = "0F";
    } else if (color === "red" || color === "green") {
        graphColor = color === "red" ? graphRed : graphGreen;
        transparency = "2B";
    } else {
        graphColor = color;
        transparency = "2B";
    }

    const data = (() => {
        if (secondaryMetadata) {
            return metadata?.data.map((d, i) => ({
                ...d,
                count: d.value,
                secondaryCount: secondaryMetadata.data[i].value,
            }));
        }
        return metadata?.data;
    })();

    if (data?.length === 0 && showEmpty) return <Empty />;
    const chart = (
        <AreaChart data={data} width={graphwidth} height={graphheight}>
            {xaxis && (
                <XAxis
                    axisLine={false}
                    dataKey={"timestamp"}
                    tickLine={false}
                    tickCount={5}
                    minTickGap={minTickGap ?? 5}
                    tickFormatter={x => moment(x).format(xTickFormat)}
                    interval={preserve}
                    dy={10}
                />
            )}
            {yaxis && (
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={y =>
                    percent ? `${((y as number) * 100).toFixed(1)}%` : y.toLocaleString()
                    }
                    interval="preserveEnd"
                    dx={-5}
                    range={range}
                />
            )}
            {!yaxis && range && <YAxis axisLine={false} tickLine={false} domain={range} hide />}
            <Area
                key="x"
                stackId="a"
                dataKey="count"
                name={seriesName}
                dot={dot}
                stroke={graphColor}
                strokeWidth={2}
                fill={`${graphColor}${transparency}`}
                type={areaType ?? "monotone"}
                min={0}
                animationDuration={animationDuration}
            />
            {grid && <CartesianGrid stroke="rgba(165,170,191,0.5)" strokeDasharray="2 7" />}
            {average && (
                <ReferenceLine
                    stroke="rgba(165,170,191,0.9)"
                    strokeDasharray="5 4"
                    y={average.toFixed(2)}
                    strokeWidth={1.5}
                />
            )}
            {legendText && <Legend formatter={() => legendText} wrapperStyle={{ bottom: -25 }} />}
            {showTooltip && (
                <Tooltip
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    formatter={(v: any, _name: any, item: any) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const formatValue = (v: any, percent: boolean) => percent ? (v * 100).toFixed(1).toString() + "%" : (v as number).toLocaleString()
                        const msg = formatValue(v, percent);

                        if (item.payload.secondaryCount !== undefined) {
                            // secondary value is opposite of primary value (e.g. if primary is %, secondary is count)
                            return `${msg} (${formatValue(item.payload.secondaryCount, !percent)})`;
                        }
                        return msg;
                    }}
                />
            )}
        </AreaChart>
    );
    if (graphheight && graphwidth) {
        return chart;
    }

    return (
        <ResponsiveContainer width={graphwidth ?? "100%"} height={graphheight} className={className}>
            {chart}
        </ResponsiveContainer>
    );
};