import React, { useEffect, useState } from "react";
import { useCustomer } from "../hooks";

import { getEWS } from "../api";

import {
  Alert,
  Tooltip as AntTooltip,
  Col,
  Collapse,
  DatePicker,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import moment from "moment";
import { MetadataField } from "../reports";

import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "../components/ReportsViewer.less";

const { Text, Title } = Typography;

type TimeseriesGroup = {
  percents: MetadataField;
  means: MetadataField;
  stds: MetadataField;
  zscores: MetadataField;
  excessVolume: MetadataField;
};

type TimeseriesGroupWithChildren = {
  groups: { [key: string]: TimeseriesGroup };
};

const graphBlue = "#465FC3";
const graphGray = "#AAAAAA";
const graphRed = "#FF4E4E";
const graphGreen = "#43B139";
const graphOrange = "#faa263";

const renderGroups = (groups: TimeseriesGroupWithChildren) => {
  return Object.entries(groups.groups).map(([key, group]) => {
    return (
      <Collapse.Panel
        key={key}
        header={
          <Space size={30}>
            <span>{key}</span>
            <div className="dot" />
            <div className="field">
              <AntTooltip
                title={() => (
                  <span>
                    Sum of unexpected contacts over period, relative to 90-day moving average
                  </span>
                )}
              >
                <div className="field-desc">
                  Excess Contacts <InfoCircleOutlined />
                </div>
                {group.excessVolume.data
                  .map(val => val.count)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString()}
              </AntTooltip>
            </div>
            <div className="dot" />
            <AreaChart data={group.zscores.data} width={135} height={55}>
              <YAxis axisLine={false} tickLine={false} domain={[-4, 4]} hide />
              <Area
                key="x"
                stackId="a"
                dataKey="count"
                stroke={"#465FC3"}
                fill={`#465FC30F`}
                strokeWidth={2}
                animationDuration={300}
                type="monotone"
              />
              <ReferenceLine stroke={graphOrange} strokeDasharray="5 4" y={-1} strokeWidth={1.5} />
              <ReferenceLine stroke={graphGray} strokeDasharray="5 4" y={0} strokeWidth={1.5} />
              <ReferenceLine stroke={graphOrange} strokeDasharray="5 4" y={1} strokeWidth={1.5} />
            </AreaChart>
            <div className="highlight" />
          </Space>
        }
        className={"groups" in group ? "superissue" : "superissue"}
      >
        <Row className="metadata" style={{ padding: "24px" }}>
          <Col span={24}>
            <ResponsiveContainer width="100%" height={320}>
              <AreaChart
                data={group.percents.data.map((val, idx) => ({
                  ...val,
                  mean: group.means.data[idx].count,
                  upper: group.means.data[idx].count + group.stds.data[idx].count,
                  lower: group.means.data[idx].count - group.stds.data[idx].count,
                }))}
              >
                <XAxis
                  axisLine={false}
                  dataKey={group.percents.fieldName}
                  tickLine={false}
                  tickCount={5}
                  minTickGap={5}
                  tickFormatter={x => moment(x).format("MMM D")}
                  interval={"preserveEnd"}
                  dy={10}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={y => `${((y as number) * 100).toFixed(1)}%`}
                  interval="preserveEnd"
                  dx={-5}
                />{" "}
                <Area
                  key="x"
                  dataKey="count"
                  stroke={"#465FC3"}
                  fill={`#465FC30F`}
                  strokeWidth={2}
                  animationDuration={150}
                  type="monotone"
                />
                <Area
                  key="x3"
                  dataKey="upper"
                  stroke={graphOrange}
                  strokeWidth={1}
                  fillOpacity={0}
                  animationDuration={150}
                  type="monotone"
                  strokeDasharray="5 4"
                />
                <Area
                  key="x2"
                  dataKey="mean"
                  stroke={graphGreen}
                  strokeWidth={1}
                  fillOpacity={0}
                  animationDuration={150}
                  type="monotone"
                  strokeDasharray="5 4"
                />
                <Area
                  key="x4"
                  dataKey="lower"
                  stroke={graphOrange}
                  strokeWidth={1}
                  fillOpacity={0}
                  animationDuration={150}
                  type="monotone"
                  strokeDasharray="5 4"
                />
                <CartesianGrid stroke="rgba(165,170,191,0.5)" strokeDasharray="2 7" />
                <Tooltip
                  formatter={(v: any) =>
                    v < 1 ? (v * 100).toFixed(1).toString() + "%" : (v as number).toLocaleString()
                  }
                />
              </AreaChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Collapse.Panel>
    );
  });
};

const EarlyWarningSystemPage: React.FC = () => {
  const { customer } = useCustomer();

  const [startDate, setStartDate] = useState(moment("2023-08-01"));
  const [endDate, setEndDate] = useState(moment("2023-09-01"));

  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState<TimeseriesGroupWithChildren>();

  const [selectedRange, setSelectedRange] = useState<number>(30);

  const handleRangeChange = (value: number) => {
    setSelectedRange(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading(true);
      try {
        getEWS(
          customer.id,
          startDate.utc().startOf("day").format("YYYY-MM-DD"),
          endDate.utc().startOf("day").format("YYYY-MM-DD"),
          selectedRange,
          customer.index.defaultTagGroupBy.join(",")
        ).then(data => {
          setData(data);
          setDataLoading(false);
        });
      } catch (error) {
        // Handle the error
      }
    };

    fetchData();
  }, [customer.id, startDate, endDate, selectedRange, customer.index.defaultTagGroupBy]);

  return (
    <div style={{ margin: "24px" }} id="reportsviewer">
      <Row style={{ margin: "24px 0 0" }}>
        <Col span={24}>
          <Title level={3}>
            Volume Anomaly Tracker{" "}
            <sup>
              <small>
                <AntTooltip
                  overlayStyle={{ maxWidth: "400px" }}
                  title={
                    <>
                      <p>
                        Spiral&apos;s Early Warning System tracks issues that are causing volume
                        spikes above their normal baselines. Issues are sorted by # of excess
                        contacts above the normal baseline.
                      </p>
                      <p>
                        Instructions for Analysis: Use the date picker to view any data window, then
                        select a moving average from the dropdown to compare the window against.
                      </p>
                    </>
                  }
                >
                  <InfoCircleOutlined style={{ color: graphGray }} />
                </AntTooltip>
              </small>
            </sup>
          </Title>
        </Col>
      </Row>
      <div style={{ width: "75%" }}>
        <Row>
          <Col span={2}>
            <Typography.Text strong>Data Window</Typography.Text>
          </Col>
          <Col span={4} offset={5}>
            <Typography.Text strong>Moving Average Period</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              onChange={dates => {
                if (dates && dates[0] && dates[1]) {
                  setStartDate(dates[0]);
                  setEndDate(dates[1]);
                }
              }}
              defaultValue={[moment(startDate), moment(endDate)]}
              className="rounded"
            />
          </Col>
          <Col span={4} offset={1}>
            <Select defaultValue={selectedRange} onChange={handleRangeChange} className="rounded">
              <Select.Option value={7}>7 days</Select.Option>
              <Select.Option value={30}>30 days</Select.Option>
              <Select.Option value={90}>90 days</Select.Option>
            </Select>
          </Col>
        </Row>
      </div>
      <Spin spinning={dataLoading}>
        <Row>
          <Col span={24}>
            <Collapse bordered={false} destroyInactivePanel>
              {data && renderGroups(data)}
            </Collapse>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default EarlyWarningSystemPage;
