import { ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Input, Row, Space, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import { endpoint, fetchWithReject, headers } from "../../../utils";
import { Customer } from "../../../indexTypes";
import ModeTooltip from "./ModeTooltip";
import { shuffle } from "lodash";
import type { TagSizeEstimate, TextSamples } from "./DataExplorer.d";

const CustomerDataExplorerPage = ({ customer: _customer }: { customer: Customer }) => {
  const getTags = async (customer: Customer) => {
    const result = await fetchWithReject(`${endpoint}/dataexplorer/${customer.id}/tags/`, {
      headers: await headers(),
      mode: "cors",
    }).then(r => r.json());
    console.debug(result?.tags);

    return result?.tags || [];
  };

  const vote = async (customer: Customer, currentTagId: string, text: string, mode: string) => {
    if (debug) {
      console.log("Skipping vote due to debug mode");
      return;
    }
    console.log(`Voting ${mode} for ${currentTagId}`);
    try {
      await fetchWithReject(`${endpoint}/dataexplorer/${customer.id}/tags/${currentTagId}/vote`, {
        method: "POST",
        headers: await headers(),
        body: JSON.stringify({
          text: text || PlaceholderText,
          vote: mode,
        }),
        mode: "cors",
      }).then(r => r.json());
    } catch (e) {
      console.error(e);
    }
  };
  const getSamples = async (customer: Customer, currentTagId: string) => {
    const result: TextSamples = await fetchWithReject(
      `${endpoint}/dataexplorer/${customer.id}/sample/${currentTagId}/${mode.name}`
    ).then(r => r.json());
    console.debug(result);
    // There can be more than one sample text, so we'll take a random one
    const array = result?.samples;

    if (!array) {
      return ["No text found (error fetching text)"];
    } else {
      return array;
    }
  };
  useEffect(() => {
    async function fetchData() {
      const tags = await getTags(_customer);
      setTags(tags);
      setCurrentTag(tags[0]);
    }
    try {
      fetchData();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [_customer]);
  const getNextSample = () => {
    if (!currentTag || !_customer) {
      return;
    }
    console.debug("Getting next sample");
    console.debug(samples);
    if (samples.length === 0) {
      console.log("Samples exhausted, fetching more");
      getSamples(_customer, currentTag.id).then(newSamples => {
        setSamples(shuffle(newSamples));
      });
    } else {
      setSamples(samples.slice(1));
    }
  };
  const modes = [
    {
      name: "Hard Negative",
      explain:
        "This definitely does not belong in the tag- it is a clear example of what the tag is not.",
    },
    {
      name: "Hard Positive",
      explain:
        "This is a clear example of what the tag is. You should only mark something as a hard positive if you are 100% sure it belongs in the tag.",
    },
  ];
  const [loading, setLoading] = React.useState<boolean>(true);
  const [debug, setDebug] = React.useState<boolean>(false);
  const [currentTag, setCurrentTag] = React.useState<TagSizeEstimate | null>(null);
  const [mode, setMode] = React.useState<{ name: string; explain: string }>(modes[0]);

  const [tags, setTags] = React.useState<TagSizeEstimate[]>([]);
  const [samples, setSamples] = React.useState<string[]>([]);
  const rowStyle: React.CSSProperties = {
    justifyContent: "center",
    width: "100%",
  };
  const [searchText, setSearchText] = React.useState<string>("");

  const handleSearch = (value: string) => {
    setSearchText(value);
    setCurrentTag(
      tags.find(tag => tag.name.toLowerCase().includes(value.toLowerCase())) || tags[0]
    );
  };

  const styles: Record<string, React.CSSProperties> = {
    dataStyle: {
      textAlign: "center",
    },
  };
  const nextMode = modes.find(m => m.name !== mode.name) || modes[0];
  const NoColor = "#FF0000"; // Red
  const YesColor = "#00FF00"; // Green
  const PlaceholderText = "No text found";
  // Neutral is a 135 degree gradient between red and green
  useEffect(() => {
    async function fetchData() {
      if (!currentTag || !_customer) {
        return;
      }
      const samples = await getSamples(_customer, currentTag.id);
      console.debug(samples);
      setSamples(samples);
    }
    fetchData();
  }, [currentTag, _customer]);
  useEffect(() => {
    if (!tags || tags.length === 0) {
      return;
    }
    if (!currentTag) {
      setCurrentTag(tags[0]);
    }
  }, [tags]);
  const getCurrentSample = () => {
    // could be inline, but here in case sampling strategy changes
    if (!samples || samples.length === 0 || !samples[0]) {
      return PlaceholderText;
    }
    return samples[0];
  };
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!currentTag) return;
      const currentSample = getCurrentSample();
      if (!currentSample || currentSample === PlaceholderText) return;
      switch (event.key) {
        case "ArrowLeft":
          vote(_customer, currentTag.id, getCurrentSample(), "negative").finally(() => {
            getNextSample();
          });
          break;
        case "ArrowRight":
          vote(_customer, currentTag.id, getCurrentSample(), "positive").finally(() => {
            getNextSample();
          });
          break;
        case "ArrowUp":
          vote(_customer, currentTag.id, getCurrentSample(), "neutral").finally(() => {
            getNextSample();
          });
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentTag, _customer, samples]);
  if (!tags || !currentTag) return <div>Loading (may be a moment)...</div>;
  // add keyboard listeners
  return (
    <>
      <Row style={{ minHeight: "400px" }} gutter={[16, 0]}>
        <Col span={12}>
          {/* left hand pane for tag view & stats */}

          <Input.Search
            placeholder="Search for a tag"
            onChange={evt => {
              handleSearch(evt.target.value);
            }}
            style={{ width: 200 }}
          />
          <Checkbox
            onChange={evt => {
              setDebug(evt.target.checked);
            }}
            style={{ marginLeft: "8px", marginBottom: "8px" }}
          >
            Debug mode (skip writes to db)
          </Checkbox>

          <Table
            dataSource={tags.filter(tag =>
              tag.name.toLowerCase().includes(searchText.toLowerCase())
            )}
            rowKey="id"
            onRow={record => {
              return {
                onClick: () => {
                  setCurrentTag(tags.find(tag => tag.id === record.id) || tags[0]);
                },
              };
            }}
          >
            <Table.Column
              title="Tag"
              sorter={(a: TagSizeEstimate, b: TagSizeEstimate) => a.name.localeCompare(b.name)}
              dataIndex="name"
              key="name"
            />
            <Table.Column
              title="Description"
              sorter={(a: TagSizeEstimate, b: TagSizeEstimate) =>
                a.description?.localeCompare(b?.description) || 0
              }
              dataIndex="description"
              key="description"
            />
            <Table.Column
              title="Tagged Texts"
              sorter={(a: TagSizeEstimate, b: TagSizeEstimate) =>
                a.tagged_text_count - b.tagged_text_count
              }
              dataIndex="tagged_text_count"
              key="tagged_text_count"
            />
            <Table.Column
              title="Hard Negatives"
              sorter={(a: TagSizeEstimate, b: TagSizeEstimate) =>
                a.hard_negative_count - b.hard_negative_count
              }
              dataIndex="hard_negative_count"
              key="hard_negative_count"
            />
            <Table.Column
              title="Hard Positives"
              sorter={(a: TagSizeEstimate, b: TagSizeEstimate) =>
                a.hard_positive_count - b.hard_positive_count
              }
              dataIndex="hard_positive_count"
              key="hard_positive_count"
            />
          </Table>
          <Row justify="center">
            <Col span={24}>
              <h2>{currentTag?.name}</h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <p>
                <i>{currentTag?.description}</i>
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={8}>
              <h3>
                Mode: <ModeTooltip mode={mode} />
              </h3>
            </Col>
            <Col span={16}>
              <Button
                onClick={() => {
                  setMode(nextMode);
                }}
              >
                Switch to <ModeTooltip mode={nextMode} /> mode
              </Button>
            </Col>
          </Row>
          <Row>
            <Space></Space>
          </Row>
          <Row>
            <Col span={24}>
              <Tooltip
                title={
                  <div>
                    <h3>Estimated Frequency:</h3>
                    <p>
                      The number of times we <i>think</i> this tag appears in the data. If this is
                      very far from your expectations, it might be an area to direct your attention.
                    </p>
                  </div>
                }
              >
                <h3>Estimated Frequency: {currentTag?.tagged_text_count}</h3>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Tooltip
                title={
                  <div>
                    <h3>Hard Negative Count:</h3>
                    <p>
                      The number of examples where we have shown where the tag definitely does{" "}
                      <i>not</i> apply. If this is very low, the tag can be prone to confusion
                      between similar topics but different ideas, (e.g &apos;payment issues vs
                      chargebacks&apos;).
                    </p>
                  </div>
                }
              >
                <h3>Hard Negative Count: {currentTag?.hard_negative_count}</h3>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Tooltip
                title={
                  <div>
                    <h3>Hard Positive Count:</h3>
                    <p>
                      The number of examples where we have shown where the tag <i>definitely</i>{" "}
                      applies. If this is very low, the tag won&apos;t know the main idea.
                      Typically, hard positives are <b>more useful</b> than hard negatives.
                    </p>
                  </div>
                }
              >
                <h3>Hard Positive Count: {currentTag?.hard_positive_count}</h3>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <h2>
              Is this an example of a <ModeTooltip mode={mode} /> for the tag
            </h2>
          </Row>
          <Row justify="center">
            <h3>
              <i>{currentTag?.name}</i>?
            </h3>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <h3 style={styles.dataStyle}>{getCurrentSample()}</h3>
              <p></p>
            </Col>
          </Row>
          <Row justify="center" style={{ height: "50vh" }}>
            <Col span={8}>
              <Button
                color={NoColor}
                onClick={() => {
                  vote(_customer, currentTag.id, getCurrentSample(), "negative").then(() => {
                    getNextSample();
                  });
                }}
                disabled={getCurrentSample() === PlaceholderText}
              >
                <h1>
                  <ArrowLeftOutlined />
                  No
                </h1>
              </Button>
            </Col>
            <Col span={8}>
              <Button
                color={`linear-gradient(135deg, ${[NoColor, YesColor].join(", ")})`}
                onClick={() => {
                  vote(_customer, currentTag.id, getCurrentSample(), "neutral").then(() => {
                    getNextSample();
                  });
                }}
                disabled={getCurrentSample() === PlaceholderText}
              >
                <h1>
                  <ArrowUpOutlined /> I don&apos; t know
                </h1>
              </Button>
            </Col>
            <Col span={8}>
              <Button
                color={YesColor}
                onClick={() => {
                  vote(_customer, currentTag.id, getCurrentSample(), "positive").then(() => {
                    getNextSample();
                  });
                }}
                disabled={getCurrentSample() === PlaceholderText}
              >
                <h1>
                  <ArrowRightOutlined />
                  Yes
                </h1>
              </Button>
            </Col>
          </Row>
          <Row>
            <Space></Space>
            {samples && getCurrentSample() !== PlaceholderText && (
              <h3>
                {samples.length} {mode.name} samples remaining to label for this tag.
              </h3>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CustomerDataExplorerPage;
