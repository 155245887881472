/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Icon, {
  BarChartOutlined,
  BugFilled,
  CameraOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CheckOutlined,
  CheckSquareTwoTone,
  CloseCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteFilled,
  DislikeFilled,
  DownloadOutlined,
  EditFilled,
  ExportOutlined,
  InfoCircleOutlined,
  LikeFilled,
  LinkOutlined,
  PercentageOutlined,
  PieChartOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import {
  Tooltip as AntTooltip,
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Empty,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Rate,
  Result,
  Row,
  Select,
  Slider,
  Space,
  Spin,
  Statistic,
  Switch,
  Tag,
  Typography,
  notification,
} from "antd";
import Linkify from "linkify-react";
import moment from "moment";
import React, { Dispatch, SetStateAction, memo, useCallback, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link, useSearchParams } from "react-router-dom";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CurveType } from "recharts/types/shape/Curve";
import { CustomerUIModel } from "../hooks";
import {
  Category,
  EvidenceCardConfig,
  Period,
  Report,
  ReportIndex,
  Sentiment,
  SortByOptions,
  TrendColors,
  isAPIError,
  keyList,
} from "../indexTypes";
import {
  API,
  APIParams,
  Judgement,
  annotateEvidence,
  moveEvidence,
  removeEvidence,
  setClusterEnabled,
  setClusterTitle,
} from "../reportApi";
import {
  Example,
  Message,
  MetadataField,
  MetadataType,
  SortableClusterSummary,
  UniqueMetadataField,
} from "../reports";
import { displayName, isDefined, stripPii, sum, truncate } from "../utils";
import { EvidenceExportModal } from "./EvidenceExportModal";
import "./ReportsViewer.less";

const formatPercent = (p: number, precision = 2) =>
  `${p < 0.0001 ? "< 0.01" : (p * 100).toFixed(precision)}%`;

const formatPeriod = (prd: Period) => {
  switch (prd) {
    case "day":
      return "d";
    case "month":
      return "mo";
    case "week":
      return "w";
    case "year":
      return "yr";
  }
};

const copyIcon = <CopyOutlined />;

const openCopyNotification = (idCopied: string) => {
  notification.open({
    message: `Copied ${idCopied}`,
    duration: 2,
  });
};

export const ratingNames = [
  "score",
  "stars",
  "Star Rating",
  "review_rating",
  "Overall Rating",
  "star_rating",
  "rating_value",
];

const graphBlue = "#465FC3";
const graphGray = "#AAAAAA";
const graphRed = "#FF4E4E";
const graphGreen = "#43B139";

export const EvidenceModal = ({
  visible,
  setVisible,
  reportIndex,
  modalType,
  evidence,
  evidenceCardConfig,
  starsText,
  starsValue,
  admin,
  removeEvidenceFromCluster,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reportIndex: ReportIndex;
  modalType?: string;
  evidence: Example;
  evidenceCardConfig?: EvidenceCardConfig;
  starsText?: string;
  starsValue?: number;
  admin: boolean;
  removeEvidenceFromCluster: (id: string) => Promise<void>;
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setShowConfirm(true);
  };
  const handleCancel = () => {
    setShowConfirm(false);
  };

  const removeEvidenceClick = (clusteredTextId: string) => {
    setConfirmLoading(true);
    removeEvidenceFromCluster(clusteredTextId)
      .then(() => {
        setConfirmLoading(false);
        setShowConfirm(false);
        setVisible(false);
      })
      .catch(() => {
        notification.open({
          message: `Failure removing evidence!`,
          duration: 5,
        });
        setConfirmLoading(false);
        setShowConfirm(false);
        setVisible(false);
      });
  };

  const evidenceIndex = evidence.messages.findIndex(
    m => m.user_type === "customer" && m.message.includes(evidence.text)
  );

  return (
    <>
      <Modal
        title={
          <div>
            {evidence.channel && (
              <span>{displayName(evidence.channel, reportIndex.displayNames)} | </span>
            )}
            {starsText && starsText}
          </div>
        }
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={[
          <Button key="close" type="primary" onClick={() => setVisible(false)}>
            Close
          </Button>,
        ]}
        bodyStyle={{ height: modalType === "review" ? "40vh" : "80vh", overflow: "hidden" }}
        width={1040}
        zIndex={9999}
        className="modal"
      >
        <Row style={{ height: "100%" }}>
          <Col span="7" style={{ overflow: "auto", maxHeight: "100%", wordBreak: "break-all" }}>
            <Space direction="vertical">
              {admin && (
                <div key="conversationId" className="selectable">
                  <AntTooltip title="Spiral Conversation ID" zIndex={10001}>
                    <b>Spiral Conversation ID:</b>
                  </AntTooltip>
                  <br />
                  <Space>
                    {evidence.conversationId}
                    <Link to={`/admin/conversations/${evidence.conversationId}`} target="_blank">
                      <Button icon={<BugFilled />} />
                    </Link>
                  </Space>
                </div>
              )}
              {evidenceCardConfig &&
                evidenceCardConfig.showConversationKey &&
                evidence.conversationKey && (
                  <div key="conversationKey" className="selectable">
                    <AntTooltip
                      title={evidenceCardConfig.conversationKeyAttributeName ?? "Conversation Key"}
                      zIndex={10001}
                    >
                      <b>
                        {displayName(evidenceCardConfig.conversationKeyAttributeName) ??
                          "Conversation Key"}
                        :
                      </b>
                    </AntTooltip>
                    <br />
                    <Space>
                      {evidence.conversationKey}
                      <CopyButton text={evidence.conversationKey} />
                    </Space>
                  </div>
                )}
              {Object.entries(evidence.metadata)
                .filter(([, v]) => v.data)
                .map(([k, v]) => (
                  <div key={k} className="selectable">
                    <AntTooltip title={k} zIndex={10001}>
                      <b>{displayName(k)}:</b>
                    </AntTooltip>
                    <br />
                    <Linkify options={{ target: "_blank" }}>
                      {JSON.stringify(v.data).replace(/^"?(.*?)"?$/, "$1")}
                    </Linkify>
                  </div>
                ))}
            </Space>
          </Col>
          <Divider type="vertical" style={{ height: "100%" }} />
          <Col span="16" style={{ overflow: "auto", maxHeight: "100%", padding: "0 15px" }}>
            {evidence.messages
              .filter(msg => msg.message.length > 0)
              .map((msg, i) => {
                const userType =
                  msg.user_type == "customer"
                    ? "customer"
                    : msg.is_agent
                    ? "agent"
                    : msg.user_type == "system" && msg.message.length > 60
                    ? "agent"
                    : msg.user_type == "system"
                    ? "system"
                    : "agent";
                const bubble =
                  modalType === "review" ? (
                    <>
                      <div style={{ paddingBottom: "20px" }}>
                        {starsValue && <Rate value={starsValue} disabled />}
                      </div>
                      <div
                        id={`modal-msg-${i}`}
                        style={{
                          color: "black",
                          width: "85%",
                          whiteSpace: "pre-wrap",
                        }}
                        className={`review customer selectable ${
                          i === evidenceIndex ? "highlight" : ""
                        }`}
                      >
                        {stripPii(msg.message)}
                      </div>
                    </>
                  ) : (
                    <div
                      id={`modal-msg-${i}`}
                      className={`message ${userType} selectable ${
                        i === evidenceIndex ? "highlight" : ""
                      }`}
                    >
                      {stripPii(msg.message)}
                    </div>
                  );
                return (
                  <div key={`modal-msg-${i}`} className="message-container">
                    {bubble}
                  </div>
                );
              })}
            {evidenceIndex >= 0 && (
              <Button
                icon={
                  <Icon
                    component={() => {
                      return (
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="currentColor">
                          <g fillRule="evenodd">
                            <g fillRule="nonzero">
                              <path d="M7.02 3.635l12.518 12.518a1.863 1.863 0 010 2.635l-1.317 1.318a1.863 1.863 0 01-2.635 0L3.068 7.588A2.795 2.795 0 117.02 3.635zm2.09 14.428a.932.932 0 110 1.864.932.932 0 010-1.864zm-.043-9.747L7.75 9.635l9.154 9.153 1.318-1.317-9.154-9.155zM3.52 12.473c.514 0 .931.417.931.931v.932h.932a.932.932 0 110 1.864h-.932v.931a.932.932 0 01-1.863 0l-.001-.931h-.93a.932.932 0 010-1.864h.93v-.932c0-.514.418-.931.933-.931zm15.374-3.727a1.398 1.398 0 110 2.795 1.398 1.398 0 010-2.795zM4.385 4.953a.932.932 0 000 1.317l2.046 2.047L7.75 7 5.703 4.953a.932.932 0 00-1.318 0zM14.701.36a.932.932 0 01.931.932v.931h.932a.932.932 0 010 1.864h-.933l.001.932a.932.932 0 11-1.863 0l-.001-.932h-.93a.932.932 0 110-1.864h.93v-.931a.932.932 0 01.933-.932z"></path>
                            </g>
                          </g>
                        </svg>
                      );
                    }}
                  />
                }
                onClick={() => {
                  document
                    .querySelector(`#modal-msg-${evidenceIndex}`)
                    ?.parentElement?.scrollIntoView({ behavior: "smooth" });

                  const messageElement = document.querySelector(
                    `#modal-msg-${evidenceIndex}`
                  )?.parentElement;
                  if (messageElement) {
                    messageElement.scrollIntoView();
                    messageElement.style.backgroundColor = "#ffbf0088";
                    messageElement.style.transition = "background-color 0.4s ease-in-out";
                    setTimeout(() => {
                      messageElement.style.backgroundColor = "transparent";
                    }, 800);
                  }
                }}
                type="primary"
                style={{ position: "sticky", bottom: 30, left: "99%", zIndex: 9001 }}
                shape="round"
              />
            )}
            {admin && (
              <Popconfirm
                title="Confirm this evidence is WRONG and should be permanently removed?"
                zIndex={10000}
                visible={showConfirm}
                onConfirm={() => removeEvidenceClick(evidence.clusteredTextId)}
                okButtonProps={{ loading: confirmLoading }}
                onCancel={handleCancel}
              >
                <Button
                  type="primary"
                  onClick={showPopconfirm}
                  danger
                  className="removeEvidenceModal"
                >
                  🚫 Remove Evidence 🚫
                </Button>
              </Popconfirm>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const findFirstInMetadata = (metadata: MetadataType, fields?: keyList): string | undefined => {
  if (!fields) {
    return undefined;
  }

  for (const field of fields) {
    if (field.key in metadata && metadata[field.key].data) {
      return metadata[field.key].data;
    }
  }

  return undefined;
};

const grey = "#d0d0d0";

const CopyButton = ({ text, tooltipTitle }: { text: string; tooltipTitle?: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timer = setInterval(() => setIsCopied(false), 1200);
      return () => clearInterval(timer);
    }
  }, [isCopied]);

  const btn = (
    <Button
      style={{ color: isCopied ? graphGreen : grey }}
      icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
      type="text"
      onClick={e => {
        setIsCopied(true);
        navigator.clipboard.writeText(text);
        e.stopPropagation();
      }}
    />
  );
  if (tooltipTitle) {
    return <AntTooltip title={tooltipTitle}>{btn}</AntTooltip>;
  } else {
    return btn;
  }
};

const CRM_URL_CONVERSATION_ID_PLACEHOLDER = "<conversation_id>";
const EvidenceCard = memo(
  ({
    evidence,
    index,
    reportIndex,
    evidenceCardConfig,
    clusterId,
    admin,
    defaultCrmUrl,
    crmName,
    screenshotMode,
    moveEvidenceFromCluster,
    removeEvidenceFromCluster,
    allClusters,
  }: {
    evidence: Example;
    index: number;
    reportIndex: ReportIndex;
    evidenceCardConfig?: EvidenceCardConfig;
    clusterId: string;
    admin: boolean;
    defaultCrmUrl?: string;
    crmName?: string;
    screenshotMode: boolean;
    moveEvidenceFromCluster: (id: string, targetCluster: string) => Promise<void>;
    removeEvidenceFromCluster: (id: string) => Promise<void>;
    allClusters: SortableClusterSummary[];
  }) => {
    const [visible, setVisible] = useState(false);
    const [annotation, setAnnotation] = useState<"positive" | "negative">();
    const [moveLoading, setMoveLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [annotationLoading, setAnnotationLoading] = useState(false);
    const [showMove, setShowMove] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const [moveSelect, setMoveSelect] = useState<string>();

    const evidenceType = evidence.messages.flatMap(m => m.type)[0];
    const textStyle = evidenceType === "review" ? "evidence-text" : "chat-bubble";

    const starsField = findFirstInMetadata(evidence.metadata, evidenceCardConfig?.starsField);
    const starsValue = starsField ? +starsField : undefined;
    let crmUrl =
      findFirstInMetadata(evidence.metadata, evidenceCardConfig?.crmUrlField) ?? defaultCrmUrl;
    if (crmUrl && crmUrl === defaultCrmUrl) {
      crmUrl = crmUrl.replace(CRM_URL_CONVERSATION_ID_PLACEHOLDER, evidence.conversationKey);
      [...crmUrl.matchAll(/<.*?>/g)]
        .map(match => match[0])
        .forEach(match => {
          const name = match.slice(1, -1);
          if (name in evidence.metadata && isDefined(crmUrl)) {
            crmUrl = crmUrl.replace(match, evidence.metadata[name].data);
          }
        });
    }
    const starsText = findFirstInMetadata(evidence.metadata, evidenceCardConfig?.starsText);

    const valueList = evidenceCardConfig?.values
      ?.filter(m => m.key in evidence.metadata)
      .map(m => evidence.metadata[m.key].data);
    const keyValueList = evidenceCardConfig?.keyValues?.filter(m => m.key in evidence.metadata);

    const annotationClick = (type: Judgement) => {
      setAnnotationLoading(true);
      const newAnnotation =
        type === "positive"
          ? annotation === "positive"
            ? undefined
            : "positive"
          : annotation === "negative"
          ? undefined
          : "negative";
      annotateEvidence(evidence.clusteredTextId, newAnnotation)
        .then(r => {
          setAnnotation(r.annotation.judgement);
        })
        .catch(() => notification.error({ message: "Failed to annotate evidence" }))
        .finally(() => {
          setAnnotationLoading(false);
        });
    };

    const moveEvidenceClick = (clusteredTextId: string, targetCluster: string) => {
      setMoveLoading(true);
      setAnnotationLoading(true);
      moveEvidenceFromCluster(clusteredTextId, targetCluster)
        .then(() => {
          setConfirmLoading(false);
          setAnnotationLoading(false);
          setShowConfirm(false);
          setVisible(false);
        })
        .catch(() => {
          // Swallow these errors for now
          setConfirmLoading(false);
          setShowConfirm(false);
          setVisible(false);
        });
    };

    const removeEvidenceClick = (clusteredTextId: string) => {
      setConfirmLoading(true);
      setAnnotationLoading(true);
      removeEvidenceFromCluster(clusteredTextId)
        .then(() => {
          setConfirmLoading(false);
          setAnnotationLoading(false);
          setShowConfirm(false);
          setVisible(false);
        })
        .catch(() => {
          // Swallow these errors for now
          setConfirmLoading(false);
          setShowConfirm(false);
          setVisible(false);
        });
    };

    return (
      <>
        {visible && (
          <EvidenceModal
            reportIndex={reportIndex}
            admin={admin}
            evidence={evidence}
            evidenceCardConfig={evidenceCardConfig}
            starsText={starsText}
            starsValue={starsValue}
            visible={visible}
            setVisible={setVisible}
            modalType={evidence.messages.flatMap(m => m.type)[0]}
            removeEvidenceFromCluster={removeEvidenceFromCluster}
          />
        )}
        <div
          key={`${clusterId}-${evidence.clusteredTextId}`}
          onClick={() => {
            if ((window.getSelection()?.toString().length ?? 0) > 0) {
              return;
            }
            if (showMove || showConfirm) {
              return;
            }
            setVisible(true);
          }}
          className="evidence"
        >
          <Space direction="vertical" style={{ width: "90%" }}>
            {admin && !screenshotMode && (
              <>
                <span>#{index}</span>
                <br />
              </>
            )}
            {evidenceCardConfig && (
              <>
                {crmUrl && !screenshotMode && (
                  <a
                    className="evidence-header-crm"
                    href={crmUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={evt => evt.stopPropagation()}
                  >
                    View in {crmName ?? "CRM"} <LinkOutlined />
                  </a>
                )}
                {starsValue && (
                  <div className="evidence-header-review">
                    <Rate style={{ paddingRight: "10px" }} value={starsValue} disabled />
                    {starsText && truncate(starsText, 43)}
                  </div>
                )}
                {valueList && valueList.length > 0 && (
                  <span className="evidence-header-metadata">{valueList.join(" | ")} </span>
                )}
                {evidenceCardConfig.showTimestamp && evidence.datasetDisplayName && (
                  <span className="evidence-header-metadata">
                    Date: {evidence.timestamp && moment(evidence.timestamp).format("YYYY-MM-DD")}
                  </span>
                )}
                {evidenceCardConfig.showSource && evidence.datasetDisplayName && (
                  <span className="evidence-header-metadata">
                    Source: {evidence.datasetDisplayName}
                  </span>
                )}
                {keyValueList && keyValueList.length > 0 && (
                  <span className="evidence-header-metadata">
                    {keyValueList.map((m, idx) => (
                      <div key={`${evidence.clusteredTextId}-${m.key}-${idx}`}>
                        {displayName(m.key, reportIndex.displayNames) +
                          ": " +
                          displayName(evidence.metadata[m.key].data, reportIndex.displayNames)}
                      </div>
                    ))}
                  </span>
                )}
                {/* This is only here for remitly. If another copyable field is needed, generalize this */}
                {evidence.customer_public_id && (
                  <Space>
                    <span>{`Customer Public ID: ${evidence.customer_public_id}`}</span>
                    <span onClick={evt => evt.stopPropagation()}>
                      <CopyToClipboard
                        text={evidence.customer_public_id ?? ""}
                        onCopy={openCopyNotification}
                      >
                        {copyIcon}
                      </CopyToClipboard>
                    </span>
                  </Space>
                )}
              </>
            )}
            {/* Evidence text body */}
            <div className={textStyle}>
              <span>{truncate(stripPii(evidence.text), 110)}</span>
            </div>
            <div className="hover-only" style={showConfirm ? { visibility: "visible" } : {}}>
              <Space size="middle" style={{ width: "100%", justifyContent: "space-between" }}>
                <Space>
                  <AntTooltip title="Evidence is Correctly Classified">
                    <Button
                      style={
                        annotation === "positive"
                          ? { visibility: "visible", color: graphBlue }
                          : { color: grey }
                      }
                      icon={<LikeFilled />}
                      type="text"
                      loading={annotationLoading}
                      onClick={e => {
                        annotationClick("positive");
                        e.stopPropagation();
                      }}
                      ghost
                    />
                  </AntTooltip>
                  <span style={{ color: grey }}>/</span>
                  <AntTooltip title="Evidence is Incorrectly Classified">
                    <Button
                      style={
                        annotation === "negative"
                          ? { visibility: "visible", color: graphBlue }
                          : { color: grey }
                      }
                      icon={<DislikeFilled />}
                      loading={annotationLoading}
                      type="text"
                      onClick={e => {
                        annotationClick("negative");
                        e.stopPropagation();
                      }}
                    />
                  </AntTooltip>
                </Space>
                <Space>
                  <CopyButton text={evidence.text} tooltipTitle="Copy Text" />
                  <AntTooltip title="Move Evidence">
                    <Popconfirm
                      icon
                      title={
                        <div
                          id="area"
                          style={{ position: "relative" }}
                          onClick={e => e.stopPropagation()}
                        >
                          <Space>
                            <label htmlFor="moveEvidenceSelect">Move Evidence to:</label>
                            <Select
                              showSearch
                              id="moveEvidenceSelect"
                              style={{ width: 200 }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label.toString() ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={allClusters
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map(c => ({ value: c.cluster, label: c.title }))}
                              value={moveSelect}
                              onChange={value => setMoveSelect(value)}
                              getPopupContainer={(triggerNode: HTMLElement) =>
                                triggerNode.parentNode as HTMLElement
                              }
                              allowClear
                            />
                          </Space>
                        </div>
                      }
                      open={showMove}
                      onConfirm={e => {
                        if (moveSelect) {
                          moveEvidenceClick(evidence.clusteredTextId, moveSelect);
                        }
                        e?.stopPropagation();
                      }}
                      okButtonProps={{ loading: moveLoading, disabled: !isDefined(moveSelect) }}
                      onCancel={e => {
                        setShowMove(false);
                        e?.stopPropagation();
                      }}
                      cancelText="Cancel"
                      okText="Move"
                    >
                      <Button
                        style={{ color: grey }}
                        icon={<ExportOutlined />}
                        type="text"
                        onClick={e => {
                          setShowMove(true);
                          e.stopPropagation();
                        }}
                      />
                    </Popconfirm>
                  </AntTooltip>
                  <AntTooltip title="Delete Evidence">
                    <Popconfirm
                      title="Are you sure this Evidence doesn't belong here, and should be permanently removed?"
                      zIndex={10000}
                      open={showConfirm}
                      onConfirm={e => {
                        removeEvidenceClick(evidence.clusteredTextId);
                        e?.stopPropagation();
                      }}
                      okButtonProps={{ danger: true, loading: confirmLoading }}
                      onCancel={e => {
                        setShowConfirm(false);
                        e?.stopPropagation();
                      }}
                      cancelText="No"
                      okText="Yes, permanently remove"
                    >
                      <Button
                        style={showConfirm ? { color: graphRed } : { color: grey }}
                        icon={<DeleteFilled />}
                        type="text"
                        onClick={e => {
                          setShowConfirm(true);
                          e.stopPropagation();
                        }}
                      />
                    </Popconfirm>
                  </AntTooltip>
                </Space>
              </Space>
            </div>
          </Space>
        </div>
      </>
    );
  }
);

const countBadgeStyles = {
  color: "#86899A",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "24px",
  height: 24,
  padding: "0 15px",
  borderRadius: "24px"
}

const ClusterHeader = memo(
  ({
    index,
    cluster,
    path,
    apiParams,
    admin,
    showNumber = true,
    period,
    periodTotal,
    trendColors,
    latestLength = 1,
    areaCurveType,
    clusterInScreenshotMode,
    screenshotSentiment,
    defaultSentiment,
    screenshotHeaderDetail,
    screenshotTitleSize,
    report,
    reportName,
    setDisableCollapse,
    disableExport,
    setDisableExport,
    showCopyURL = true,
    usePercent = true,
  }: {
    index: number;
    cluster: SortableClusterSummary;
    path: string[];
    apiParams: APIParams;
    period: Period;
    periodTotal: number;
    admin: boolean;
    showNumber?: boolean;
    trendColors?: TrendColors;
    latestLength?: number;
    areaCurveType?: CurveType;
    clusterInScreenshotMode?: string;
    screenshotSentiment?: Sentiment;
    defaultSentiment?: Sentiment;
    screenshotHeaderDetail: "less" | "more";
    screenshotTitleSize: number;
    report: Report;
    reportName: string;
    setDisableCollapse: (isOpen: boolean) => void;
    disableExport: boolean;
    setDisableExport: (disableExport: boolean) => void;
    showCopyURL?: boolean;
    usePercent?: boolean;
  }) => {
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [downloadIsLoading, setDownloadIsLoading] = useState(false);

    const overallPercent = cluster.size / periodTotal;
    const latestPeriodTotal = cluster.latestPeriodTotal;
    const latestPeriodPercent = cluster.latestPeriodPercent;
    const delta = cluster.latestPeriodTrend;

    const isSuperCluster = isDefined(cluster.children);
    const isMegaCluster = cluster.children?.some(c => isDefined(c.children));

    const screenshotMode = clusterInScreenshotMode === cluster.cluster;

    const sentiment = cluster.sentiment ?? defaultSentiment;

    const deltaSentimentThresholdInDeg = report.reportIndexJson.sentimentThreshold ?? 5.0;
    const slopeInDegrees = Math.atan(cluster.latestPeriodTrendSlope) * (180 / Math.PI);

    const opts = ["mid", "sid", "cid"];
    const pathParams = (cluster.children ? opts.slice(0, path.length) : opts.slice(-path.length))
      .map((k, i) => (i < path.length ? `${k}=${path[i]}` : undefined))
      .filter(isDefined)
      .join("&");

    const shareUrl = `${window.location.origin}/dashboards/${report.urlHash}?${pathParams}`;

    let highlight;

    if (isSuperCluster) {
      highlight = <div className="highlight" />;
    } else if (screenshotMode || cluster.sentiment || defaultSentiment) {
      let sent = cluster.sentiment ?? defaultSentiment;
      if (screenshotMode && screenshotSentiment) {
        sent = screenshotSentiment;
      }
      highlight = <div className={`highlight ${sent}`} />;
    }

    const sentimentColors = {
      positive: trendColors?.down ?? graphGreen,
      neutral: trendColors?.flat ?? graphGray,
      negative: trendColors?.up ?? graphRed,
      default: graphBlue,
    };

    const screenshotSentimentColor =
      (screenshotSentiment ?? cluster.sentiment ?? defaultSentiment) === "negative"
        ? sentimentColors.negative
        : sentimentColors.positive;

    /*
    Trend graph color reflects a combination of the cluster's sentiment and the trend's direction.
    If the trend line's slope (as an angle in degrees) is steeper than the threshold angle we set for the report (or
    the default angle of 5 degrees), we consider it to be trending upward (or downward if the slope is negative).
    e.g. negative sentiment + trending upward = negative graph color
    */
    let normalSentimentColor;
    if (sentiment === "negative") {
      normalSentimentColor =
        slopeInDegrees >= deltaSentimentThresholdInDeg
          ? sentimentColors.negative
          : slopeInDegrees < deltaSentimentThresholdInDeg &&
            slopeInDegrees > -deltaSentimentThresholdInDeg
          ? sentimentColors.neutral
          : sentimentColors.positive;
    } else if (sentiment === "positive") {
      normalSentimentColor =
        slopeInDegrees >= deltaSentimentThresholdInDeg
          ? sentimentColors.positive
          : slopeInDegrees < deltaSentimentThresholdInDeg &&
            slopeInDegrees > -deltaSentimentThresholdInDeg
          ? sentimentColors.neutral
          : sentimentColors.negative;
    } else {
      normalSentimentColor = sentimentColors.neutral;
    }

    const sentimentColor = screenshotMode ? screenshotSentimentColor : normalSentimentColor;

    const [title, setTitle] = useState(cluster.title ?? "[No Title Yet]");
    const [titleEditMode, setTitleEditMode] = useState(false);

    const [updatingTitle, setUpdatingTitle] = useState(false);
    const [enablingDisablingCluster, setEnablingDisablingCluster] = useState(false);

    const disableCSVDownload = () => {
      const MIN_MESSAGES = 10;
      return disableExport || cluster.size <= MIN_MESSAGES;
    };

    let tags = null;
    if (cluster.children && cluster.children.length > 0) {
      const tagWidth = "130px";
      if (isMegaCluster) {
        tags = (
          <>
            <Button key="Mega Cluster Topic Count" type="primary" style={{ width: tagWidth }}>
              {cluster.children?.length} Topics
            </Button>
            <Button
                key="Mega Cluster Issue Count"
                type="primary"
                style={{
                  background: "white",
                  color: graphBlue,
                  borderColor: graphBlue,
                  border: "3px solid",
                  width: tagWidth
                }}
            >
              {cluster.children?.map( child => child.children ? child.children.length : 0).reduce((a, b) => a + b, 0)} Issues
            </Button>
          </>
        );
      } else if (isSuperCluster) {
        tags = (
          <>
            <Button key={"Super Cluster Issue Count"} type="primary" style={{ width: tagWidth }}>
              {cluster.children?.length} Issues
            </Button>
          </>
        );
      }
    }

    return (
      <>
        <EvidenceExportModal
          cluster={cluster}
          apiParams={apiParams}
          reportUrlHash={report.urlHash}
          isModalOpen={isExportModalOpen}
          setIsModalOpen={setIsExportModalOpen}
          downloadIsLoading={downloadIsLoading}
          setDownloadIsLoading={setDownloadIsLoading}
          setDisableCollapse={setDisableCollapse}
          setDisableExport={setDisableExport}
        />
        <Space
          size={screenshotMode ? 15 : 30}
          style={screenshotMode ? { fontSize: screenshotTitleSize } : {}}
        >
          {!screenshotMode && admin && cluster.titled && (
            <AntTooltip title={() => <span>Titled</span>}>
              <CheckSquareTwoTone
                style={{ color: "green", marginRight: 10 }}
                twoToneColor={graphGreen}
              />
            </AntTooltip>
          )}
          {!screenshotMode && admin && (
            <AntTooltip title={() => <span>Enable/disable cluster</span>}>
              <Switch
                defaultChecked={cluster.enabled}
                onChange={checked => {
                  setEnablingDisablingCluster(true);
                  const f = async () => {
                    try {
                      await setClusterEnabled(cluster.cluster, reportName, checked);
                    } finally {
                      setEnablingDisablingCluster(false);
                    }
                  };
                  f();
                  cluster.enabled = checked;
                }}
                loading={enablingDisablingCluster}
                disabled={enablingDisablingCluster}
              />
            </AntTooltip>
          )}
          {titleEditMode ? (
            <Space>
              <Input
                placeholder={cluster.title}
                value={title}
                onChange={e => setTitle(e.target.value)}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                spellCheck
              />
              <Space>
                {" "}
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setUpdatingTitle(true);
                    const f = async () => {
                      try {
                        await setClusterTitle(cluster.cluster, title.trim());
                      } finally {
                        setTitleEditMode(false);
                        setUpdatingTitle(false);
                      }
                    };
                    f();
                  }}
                  loading={updatingTitle}
                  disabled={updatingTitle || title.trim().length === 0}
                />
                <Button
                  icon={<CloseOutlined />}
                  onClick={e => {
                    setTitle(cluster.title);
                    setTitleEditMode(false);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  disabled={updatingTitle}
                />
              </Space>
            </Space>
          ) : (
            <span>
              {showNumber && !screenshotMode && `#${index + 1} `}
              {truncate(
                (report.reportIndexJson.useDisplayNamesForTitles ?? false) && isSuperCluster
                  ? displayName(title)
                  : title,
                90
              )}
              {!isSuperCluster && admin && !screenshotMode && (
                <>
                  {" "}
                  <EditFilled
                    onClick={e => {
                      setTitleEditMode(!titleEditMode);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                </>
              )}
            </span>
          )}
          <div className="dot" />
          {screenshotMode && screenshotHeaderDetail === "less" && (
            <>
              <div className="field">
                <span
                  style={{
                    color: sentimentColor,
                  }}
                >
                  {formatPercent(latestPeriodPercent)}
                </span>
              </div>
              <div style={{ height: 55, width: 1 }} />
            </>
          )}
          {(!screenshotMode || screenshotHeaderDetail === "more") && (
            <>
              <div className="field">
                <AntTooltip
                  title={() => <span>Latest Volume ({latestPeriodTotal.toLocaleString()})</span>}
                >
                  <div className="field-desc">
                    Latest <InfoCircleOutlined />
                  </div>

                  <div className="percent-and-count">
                    <span style={{ color: graphBlue }}>{formatPercent(latestPeriodPercent)}</span>
                    <Badge
                      overflowCount={10**9}
                      showZero={true}
                      style={countBadgeStyles}
                      count={latestPeriodTotal.toLocaleString()} color="#EFF0F8"/>
                  </div>
                </AntTooltip>
              </div>
              <div className="dot" />
              <div className="field">
                <AntTooltip
                  title={() => <span>Overall Volume ({cluster.size.toLocaleString()})</span>}
                >
                  <div className="field-desc">
                    Overall <InfoCircleOutlined />
                  </div>

                  <div className="percent-and-count">
                    <span>{formatPercent(overallPercent)}</span>
                    <Badge
                      className="count-badge"
                      overflowCount={10**9}
                      showZero={true}
                      style={countBadgeStyles}
                      count={cluster.size.toLocaleString()} color="#EFF0F8"/>
                  </div>
                </AntTooltip>
              </div>
              <div className="dot" />
              <Space size="middle">
                <div className="field">
                  <AntTooltip
                    title={() => (
                      <span>
                        Change in Volume over {latestLength} {period}
                        {latestLength > 1 ? "s" : ""}{" "}
                      </span>
                    )}
                  >
                    <div className="field-desc">
                      {latestLength}
                      {formatPeriod(period)} Change <InfoCircleOutlined />
                    </div>

                    <span
                      style={{
                        color: sentimentColor,
                      }}
                    >
                      {`${delta > 0 ? "+" : ""} ${delta.toFixed(2)}%`}
                      {delta > 0 ? (
                        <CaretUpOutlined style={{ padding: "0 7px" }} />
                      ) : (
                        <CaretDownOutlined style={{ padding: "0 7px" }} />
                      )}
                    </span>
                  </AntTooltip>
                </div>
                <TimeseriesChart
                  metadata={usePercent ? cluster.weeklyAveragePercentage : cluster.weeklyAverageCount}
                  graphheight={55}
                  graphwidth={132}
                  color={sentimentColor}
                  areaType={areaCurveType}
                  percent={usePercent}
                />
              </Space>
            </>
          )}
        </Space>
        {!isSuperCluster && cluster.category && (
          <Space className="category-tag-wrapper">
            <Tag className={`category-tag-${cluster.category}`}>
              {displayName(cluster.category)}
            </Tag>
          </Space>
        )}
        <Space size={10} className="copy-button">
          {!isMegaCluster && (
            <Button
              className="download-button"
              icon={<DownloadOutlined />}
              type="text"
              loading={downloadIsLoading}
              disabled={disableCSVDownload()}
              onClick={e => {
                e.stopPropagation();
                setDisableCollapse(true);
                setIsExportModalOpen(true);
              }}
            />
          )}
          {showCopyURL && (
            <Typography.Text
              copyable={{
                text: shareUrl,
                tooltips: ["Copy URL", "Copied"],
                icon: <LinkOutlined />,
              }}
              className="selectable"
            />
          )}
        </Space>
        <Space size={19} className="tags">
          {tags}
        </Space>
        {highlight}
      </>
    );
  }
);

const ClusterOverview = memo(
  ({
    apiParams,
    cluster: clusterSummary,
    admin,
    analyzeBy,
    overallData,
    type,
    setType,
    normalized,
    setNormalized,
    showEvidences = true,
    crmName,
    displayNames,
    hideEvidencesBelow,
    report,
    screenshotMode: clusterInScreenshotMode,
    setScreenshotMode,
    allClusters,
    usePercent,
  }: {
    apiParams: APIParams;
    cluster: SortableClusterSummary;
    admin: boolean;
    includeHeader?: boolean;
    gridHeight?: string;
    analyzeBy?: string;
    overallData?: MetadataField;
    type: ChartType;
    setType: (t: ChartType) => void;
    normalized: boolean;
    setNormalized: (n: boolean) => void;
    showEvidences?: boolean;
    crmName?: string;
    displayNames: { [k: string]: string } | undefined;
    hideEvidencesBelow?: number;
    report: Report;
    screenshotMode: string | undefined;
    setScreenshotMode: (cluster?: string) => void;
    allClusters: SortableClusterSummary[];
    usePercent: boolean;
  }) => {
    const clusterId = clusterSummary.cluster;
    const [showMore, setShowMore] = useState(false);
    const [allExamples, setAllExamples] = useState<Example[]>();
    const [examples, setExamples] = useState<Example[]>();
    const [isLoading, setIsLoading] = useState(false);
    const reportIndex = report.reportIndexJson;
    const aboveTheFold = reportIndex.maxNumberOfEvidences ?? 6;

    const screenshotMode = clusterInScreenshotMode === clusterId;

    const doLoadEvidences = showEvidences && (report.reportIndexJson.loadEvidences ?? true);

    const shareUrl = `https://app.spiralup.co/dashboards/${report.urlHash}?cid=${clusterId}${
      clusterSummary.superclusterId ? "&sid=" + clusterSummary.superclusterId : ""
    }`;

    const moveEvidenceFromCluster = async (clusteredTextId: string, targetCluster: string) => {
      try {
        setIsLoading(true);
        const controller = new AbortController();
        const resp = await moveEvidence(clusteredTextId, targetCluster);
        if (isAPIError(resp)) {
          console.error(`Error removing evidence: ${resp}`);
          throw new Error(resp.description);
        }

        const evidenceResp = await API.evidences(controller.signal, apiParams, clusterId, true);
        if (isAPIError(evidenceResp)) {
          console.error(`Error re-fetching evidences: ${evidenceResp}`);
          throw new Error(evidenceResp.description);
        }
        setAllExamples(evidenceResp);
      } finally {
        setIsLoading(false);
      }
      return;
    };

    const removeEvidenceFromCluster = async (clusteredTextId: string) => {
      try {
        setIsLoading(true);
        const controller = new AbortController();
        const removeResp = await removeEvidence(clusteredTextId);
        if (isAPIError(removeResp)) {
          console.error(`Error removing evidence: ${removeResp}`);
          throw new Error(removeResp.description);
        }

        const evidenceResp = await API.evidences(controller.signal, apiParams, clusterId, true);
        if (isAPIError(evidenceResp)) {
          console.error(`Error re-fetching evidences: ${evidenceResp}`);
          throw new Error(evidenceResp.description);
        }
        setAllExamples(evidenceResp);
      } finally {
        setIsLoading(false);
      }
      return;
    };

    // Fetch evidences for an expanded cluster
    useEffect(() => {
      const controller = new AbortController();
      const loadExamples = async () => {
        setIsLoading(true);
        try {
          const e = await API.evidences(controller.signal, apiParams, clusterId);
          setAllExamples(e);
        } finally {
          setIsLoading(false);
        }
      };
      if (doLoadEvidences) {
        loadExamples();
        return () => controller.abort();
      }
    }, [apiParams, clusterId, doLoadEvidences]);

    useEffect(() => {
      if (!allExamples) return;
      if (admin && !screenshotMode) {
        setExamples(allExamples);
      } else {
        setExamples(allExamples.slice(0, showMore ? 21 : aboveTheFold));
      }
    }, [allExamples, showMore, admin, screenshotMode]);

    if (!admin && !clusterSummary.enabled) {
      return null;
    }

    return (
      <>
        <Row style={{ padding: "24px" }} className="metadata">
          {admin && !clusterSummary.children && !screenshotMode && (
            <Col span={24}>
              <Space size="middle">
                <Typography.Text
                  copyable={{ text: shareUrl, tooltips: ["Copy URL", "Copied"] }}
                  className="selectable"
                >
                  {clusterId}
                </Typography.Text>
                <Button
                  icon={<CameraOutlined />}
                  shape="circle"
                  onClick={() => setScreenshotMode(clusterId)}
                ></Button>
                <Button icon={<SelectOutlined />} shape="circle" />
              </Space>
            </Col>
          )}
          <Col span={16}>
            <Statistic value=" " style={{ marginBottom: "1em" }} />
            <TimeseriesChart
              metadata={usePercent ? clusterSummary.weeklyAveragePercentage : clusterSummary.weeklyAverageCount}
              secondaryMetadata={usePercent ? clusterSummary.weeklyAverageCount : clusterSummary.weeklyAveragePercentage}
              graphheight={320}
              xaxis
              yaxis={!screenshotMode}
              grid
              animationDuration={150}
              preserve={"preserveEnd"}
              xTickFormat={reportIndex.xTickFormat ?? "MMM D"}
              minTickGap={reportIndex.minTickGap}
              areaType={reportIndex.areaCurveType}
              seriesName={usePercent ? "Percent" : "Count"}
              showTooltip
              percent={usePercent}
            />
          </Col>
          <Col span={8}>
            {analyzeBy ? (
              <MetadataWidget
                apiParams={apiParams}
                analyzeBy={analyzeBy}
                clusters={
                  clusterSummary.supercluster
                    ? clusterSummary.children
                        ?.flatMap(c => (c.children ? c.children : [c])) // HACK to support megaclusters
                        .map(c => c.cluster)
                    : [clusterSummary.cluster]
                }
                title={analyzeBy}
                type={type}
                setType={setType}
                overallData={overallData}
                normalized={normalized}
                setNormalized={setNormalized}
                displayNames={displayNames}
                limit={reportIndex.analyzeByLimit}
                yaxis={!screenshotMode}
                emptyDescription={clusterSummary.supercluster ? "Not Supported Yet!" : undefined}
              />
            ) : (
              "No Data"
            )}
          </Col>
        </Row>
        {doLoadEvidences && (
          <>
            <Row style={{ margin: "24px" }}>
              <Col span={24}>
                <Spin spinning={!isDefined(examples) || isLoading}>
                  {examples &&
                  (admin ||
                    !isDefined(hideEvidencesBelow) ||
                    examples.length > hideEvidencesBelow) ? (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        alignItems: "stretch",
                      }}
                    >
                      {!screenshotMode && (
                        <>
                          <div style={{ flexBasis: "50%", padding: "20px", margin: "10px 0 5px" }}>
                            <span>Top Evidences:</span>
                          </div>
                          <div
                            style={{
                              flexBasis: "50%",
                              padding: "20px",
                              margin: "10px 0 5px",
                              textAlign: "right",
                            }}
                          >
                            <span>
                              Tip: Snippets are simply &quot;Issue Clues&quot; click one to see the
                              full story
                            </span>
                          </div>
                        </>
                      )}
                      {examples.map((e, i) => (
                        <EvidenceCard
                          key={e.clusteredTextId}
                          evidence={e}
                          reportIndex={reportIndex}
                          index={i}
                          evidenceCardConfig={reportIndex.evidenceCard}
                          clusterId={clusterId}
                          admin={admin}
                          defaultCrmUrl={reportIndex.defaultCRMUrl}
                          crmName={crmName}
                          screenshotMode={screenshotMode}
                          moveEvidenceFromCluster={moveEvidenceFromCluster}
                          removeEvidenceFromCluster={removeEvidenceFromCluster}
                          allClusters={allClusters}
                        />
                      ))}
                    </div>
                  ) : (
                    <Empty
                      style={{ padding: "30px 0" }}
                      description="Evidences can only be shown with higher feedback volume"
                    />
                  )}
                </Spin>
              </Col>
            </Row>
            {!admin &&
              !reportIndex.maxNumberOfEvidences &&
              allExamples &&
              allExamples.length > aboveTheFold && (
                <Row justify="center">
                  <Col style={{ marginRight: "70px" }}>
                    <Button onClick={() => setShowMore(!showMore)}>
                      {!showMore ? "Show More" : "Show Less"}
                    </Button>
                  </Col>
                </Row>
              )}
          </>
        )}
      </>
    );
  }
);

export const TimeseriesChart = ({
  metadata,
  secondaryMetadata,
  graphheight,
  graphwidth,
  yaxis,
  xaxis,
  range,
  className,
  color,
  grid,
  animationDuration = 300,
  dot = false,
  average,
  percent = true,
  xTickFormat = "D MMM 'YY",
  preserve = "preserveStart",
  legendText,
  showTooltip = false,
  seriesName = "Conversations",
  minTickGap,
  areaType,
  showEmpty = true,
}: {
  metadata: MetadataField | undefined;
  secondaryMetadata?: MetadataField;
  graphheight: number;
  graphwidth?: number;
  yaxis?: boolean;
  xaxis?: boolean;
  range?: Array<number>;
  className?: string;
  color?: string;
  grid?: boolean;
  animationDuration?: number;
  dot?: boolean;
  daysToChart?: number;
  average?: number;
  percent?: boolean;
  xTickFormat?: string;
  preserve?: "preserveStart" | "preserveEnd";
  legendText?: string;
  showTooltip?: boolean;
  seriesName?: string;
  minTickGap?: number;
  areaType?: CurveType;
  showEmpty?: boolean;
}) => {
  let graphColor;
  let transparency;
  // eslint-disable-next-line id-blacklist
  if (color === undefined) {
    graphColor = graphBlue;
    transparency = "0F";
  } else if (color === "red" || color === "green") {
    graphColor = color === "red" ? graphRed : graphGreen;
    transparency = "2B";
  } else {
    graphColor = color;
    transparency = "2B";
  }
  const data = (() => {
    if (secondaryMetadata) {
      return metadata?.data.map((d, i) => ({
        ...d,
        count: d.count,
        secondaryCount: secondaryMetadata.data[i].count,
      }));
    }
    return metadata?.data;
  })();

  if (data?.length === 0 && showEmpty) return <Empty />;
  const chart = (
    <AreaChart data={data} width={graphwidth} height={graphheight}>
      {xaxis && (
        <XAxis
          axisLine={false}
          dataKey={metadata?.fieldName}
          tickLine={false}
          tickCount={5}
          minTickGap={minTickGap ?? 5}
          tickFormatter={x => moment(x).format(xTickFormat)}
          interval={preserve}
          dy={10}
        />
      )}
      {yaxis && (
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={y =>
            percent ? `${((y as number) * 100).toFixed(1)}%` : y.toLocaleString()
          }
          interval="preserveEnd"
          dx={-5}
          range={range}
        />
      )}
      {!yaxis && range && <YAxis axisLine={false} tickLine={false} domain={range} hide />}
      <Area
        key="x"
        stackId="a"
        dataKey="count"
        name={seriesName}
        dot={dot}
        stroke={graphColor}
        strokeWidth={2}
        fill={`${graphColor}${transparency}`}
        type={areaType ?? "monotone"}
        min={0}
        animationDuration={animationDuration}
      />
      {grid && <CartesianGrid stroke="rgba(165,170,191,0.5)" strokeDasharray="2 7" />}
      {average && (
        <ReferenceLine
          stroke="rgba(165,170,191,0.9)"
          strokeDasharray="5 4"
          y={average.toFixed(2)}
          strokeWidth={1.5}
        />
      )}
      {legendText && <Legend formatter={() => legendText} wrapperStyle={{ bottom: -25 }} />}
      {showTooltip && (
        <Tooltip
        formatter={(v: any, _name: any, item: any) => {
            const formatValue = (v: any, percent: boolean) => percent ? (v * 100).toFixed(1).toString() + "%" : (v as number).toLocaleString()
            const msg = formatValue(v, percent);

            if (item.payload.secondaryCount !== undefined) {
              // secondary value is opposite of primary value (e.g. if primary is %, secondary is count)
              return `${msg} (${formatValue(item.payload.secondaryCount, !percent)})`;
            }
            return msg;
          }}
        />
      )}
    </AreaChart>
  );
  if (graphheight && graphwidth) {
    return chart;
  }
  return (
    <ResponsiveContainer width={graphwidth ?? "100%"} height={graphheight} className={className}>
      {chart}
    </ResponsiveContainer>
  );
};

const colors = ["#465FC3", "RGBA(70,95,195,0.39)", "#141446", "#7A77FF"];
const piecolors = ["#223065", "#4558A5", "#7080C2", "#B3BDE7", "#DBE0F5"];

export type ChartType = "pie" | "bar";

const MetadataWidget = memo(
  ({
    apiParams,
    analyzeBy,
    clusters,
    title,
    denominator,
    type,
    setType,
    overallData,
    normalized = false,
    setNormalized,
    displayNames,
    limit = -1,
    yaxis = true,
    emptyDescription,
  }: {
    apiParams: APIParams;
    analyzeBy: string;
    clusters?: string[];
    title: string;
    denominator?: number;
    type: ChartType;
    setType: (t: ChartType) => void;
    overallData?: MetadataField;
    normalized?: boolean;
    setNormalized?: (n: boolean) => void;
    displayNames: { [k: string]: string } | undefined;
    limit?: number;
    yaxis?: boolean;
    emptyDescription?: string;
  }) => {
    const overallTotal = overallData ? overallData.data.reduce((a, b) => a + b.count, 0) : 1;
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState<{ key: string; value: number; name: string }[]>();

    useEffect(() => {
      const controller = new AbortController();

      async function loadMetadata() {
        setIsLoading(true);
        try {
          const metadata = await (clusters
            ? API.clusterMetadata(controller.signal, apiParams, analyzeBy, clusters, limit)
            : API.metadata(controller.signal, apiParams, analyzeBy, limit));
          const total = metadata.data.reduce((a, b) => a + b.count, 0) ?? 1;
          const d = metadata.data
            .map(a => ({
              key: a["field"],
              value: (a.count / (denominator ?? total)) * 100,
              count: a.count,
              name: displayName(a["field"], displayNames),
            }))
            .sort((a, b) => {
              if (ratingNames.includes(analyzeBy)) {
                return (a.key as unknown as number) - (b.key as unknown as number);
              }
              return b.value - a.value;
            })
            .map(a => ({
              ...a,
              value:
                normalized && overallData
                  ? Math.round(
                      (a.value -
                        Math.round(
                          ((overallData.data.find(x => x.field === a.key)?.count ?? 0) /
                            overallTotal) *
                            1000
                        ) /
                          10) *
                        100
                    ) / 100
                  : a.value,
            }));
          setChartData(d);
        } finally {
          setIsLoading(false);
        }
      }
      loadMetadata();
      return () => controller.abort();
    }, [
      apiParams,
      analyzeBy,
      clusters,
      limit,
      denominator,
      displayNames,
      normalized,
      overallData,
      overallTotal,
    ]);

    const formatter = (n: any, _name: any, item: any) => {
      const msg = `${Math.round(n * 10) / 10}%`;
      if (normalized) {
        return msg;
      }
      return `${msg} (${item.payload.count})`;
    }

    return (
      <div style={{ paddingLeft: "25px", paddingRight: "15px", width: "100%" }}>
        <Row justify="space-between">
          <Col span={24} style={{ textAlign: "center" }}>
            <Statistic
              value={displayName(title, displayNames)}
              style={{ marginBottom: "0.1em", fontSize: "20px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: "right", marginBottom: "0em" }}>
            <Space>
              <span>
                <Button icon={<BarChartOutlined />} type="link" onClick={() => setType("bar")} />
                <Button
                  icon={<PieChartOutlined />}
                  type="link"
                  onClick={() => {
                    setType("pie");
                    if (setNormalized) setNormalized(false);
                  }}
                />
              </span>
              {setNormalized && (
                <>
                  <span style={{ color: "#666" }}>|</span>
                  <AntTooltip
                    title={() => (
                      <span>
                        <strong>Normalize</strong>
                        <br />
                        Show Change from Overall
                      </span>
                    )}
                  >
                    <Button
                      icon={<PercentageOutlined />}
                      type={normalized ? "primary" : "link"}
                      onClick={() => setNormalized(!normalized)}
                      shape="circle"
                    />
                  </AntTooltip>
                </>
              )}
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{ width: "100%", height: "100%" }}>
              <Spin spinning={isLoading}>
                {chartData?.length === 0 ? (
                  <div
                    style={{
                      height: 300,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Empty description={emptyDescription} />
                  </div>
                ) : (
                  <ResponsiveContainer height={300}>
                    {type === "bar" || normalized ? (
                      <BarChart data={chartData}>
                        <XAxis dataKey="name" axisLine={false} tickLine={false} />
                        {yaxis && <YAxis unit="%" axisLine={false} tickLine={false} />}
                        <CartesianGrid
                          vertical={false}
                          stroke="rgba(165,170,191,0.5)"
                          strokeDasharray="2 7"
                        />
                        <Tooltip isAnimationActive={false} formatter={formatter} />
                        <Bar dataKey="value" fill={colors[0]} radius={10} />
                        {/* <Legend formatter={v => displayName(v)} /> */}
                      </BarChart>
                    ) : (
                      <PieChart>
                        <Tooltip isAnimationActive={false} formatter={formatter} />
                        <Pie
                          key={Math.random()}
                          data={chartData}
                          dataKey="value"
                          animationDuration={250}
                          labelLine={false}
                          label={({ name, value }) =>
                            value >= 4 ? truncate(name, 14, false) : undefined
                          }
                        >
                          {chartData &&
                            chartData.map((entry, index) => (
                              <Cell key={entry.key} fill={piecolors[index % piecolors.length]} />
                            ))}
                        </Pie>
                      </PieChart>
                    )}
                  </ResponsiveContainer>
                )}
              </Spin>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
);

export type ContactMessages = {
  channel?: string;
  customer: string;
  messages: Message[];
  uniqueMetadata?: {
    [field: string]: UniqueMetadataField;
  };
};

const clusterComparator =
  (sortBy: SortByOptions) => (a: SortableClusterSummary, b: SortableClusterSummary) => {
    if (sortBy === "latest") {
      return b.latestPeriodPercent - a.latestPeriodPercent;
    }
    if (sortBy === "overall") {
      return b.size - a.size;
    }
    const aTrend = a.latestPeriodTrend;
    const bTrend = b.latestPeriodTrend;
    if (sortBy === "trend") {
      return Math.abs(bTrend) - Math.abs(aTrend);
    }
    if (sortBy === "increasing") {
      return bTrend - aTrend;
    }
    return aTrend - bTrend;
  };

// eslint-disable-next-line react/display-name
export const ClusterList = memo(
  ({
    apiParams,
    sentimentFilter,
    categoryFilter,
    useSuperclusters,
    showDisabled,
    report,
    sortBy,
    admin,
    trendColors,
    analyzeBy,
    stat,
    customer,
    chartType,
    setChartType,
    normalized,
    setNormalized,
    searchInput,
  }: {
    apiParams: APIParams;
    sentimentFilter?: Sentiment;
    categoryFilter?: Category;
    useSuperclusters: boolean;
    showDisabled: boolean;
    report: Report;
    sortBy: SortByOptions;
    admin: boolean;
    trendColors?: TrendColors;
    analyzeBy?: string;
    stat: number;
    customer: CustomerUIModel;
    chartType: ChartType;
    setChartType: (t: ChartType) => void;
    normalized: boolean;
    setNormalized: (n: boolean) => void;
    searchInput?: string;
  }) => {
    const reportIndex = report.reportIndexJson;
    const [clusters, setClusters] = useState<SortableClusterSummary[]>();
    const [metadata, setMetadata] = useState<MetadataField>();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [response, setResponse] = useState<{
      clusters: SortableClusterSummary[];
      superclusters: SortableClusterSummary[];
    }>();

    const [searchParams] = useSearchParams();
    const showClusterId = searchParams.get("cid") || "";
    const showSuperclusterId = searchParams.get("sid") || "";
    const showMegaclusterId = searchParams.get("mid") || "";

    const [clusterInScreenshotMode, setClusterInScreenshotMode] = useState<string>();
    const [screenshotSentiment, setScreenshotSentiment] = useState<Sentiment>();
    const [screenshotHeaderDetail, setScreenshotHeaderDetail] = useState<"less" | "more">("more");
    const [screenshotTitleSize, setScreenshotTitleSize] = useState(20);

    const [disableCollapse, setDisableCollapse] = useState(false);
    const [disableExport, setDisableExport] = useState(false);


    const setScreenshotMode = useCallback((clusterId?: string) => {
      setClusterInScreenshotMode(clusterId);
    }, []);

    const showClusterPath = [showMegaclusterId, showSuperclusterId, showClusterId].filter(
      x => x !== ""
    );

    // Expand the correct cluster if the URL has a cluster id
    useEffect(() => {
      const f = async () => {
        if (showClusterPath.length == 0) {
          return;
        }
        const toShow = showClusterPath[showClusterPath.length - 1];
        // wait until a frame when the cluster is actually showing before scrolling to it
        // more better option may be something with MutationObserver
        while (!isDefined(document.getElementById(toShow))) {
          await new Promise(r => requestAnimationFrame(r));
        }
        document.getElementById(toShow)!.scrollIntoView();
      };
      if (isLoading === false) {
        f();
      }
    }, [showClusterPath, isLoading]);

    // Fetch the cluster list for ... and fetch total volume to create the cluster/super cluster %'s
    useEffect(() => {
      const controller = new AbortController();

      const f = async (controller: AbortController) => {
        try {
          setIsLoading(true);

          const baseClusters = await API.clusters(
            controller.signal,
            apiParams,
            report.id,
            showDisabled,
            sentimentFilter ?? "all",
            categoryFilter ?? "",
            report.reportIndexJson.useMegaclusters ?? false
          );
          if (controller.signal.aborted) return;
          setIsLoading(false);
          setIsError(false);
          setResponse(baseClusters);
        } catch (e) {
          if (controller.signal.aborted) return;
          setIsLoading(false);
          setIsError(true);
        }
      };

      f(controller);

      return () => controller.abort();
    }, [apiParams, showDisabled, sentimentFilter, categoryFilter, report]);

    // Once the clusters are fetched from the API, build the cluster and super clusters. Drives the useSuperclusters
    // toggle UI feature.
    useEffect(() => {
      if (!response || !response.superclusters) return;
      if (useSuperclusters) {
        setClusters([
          ...response.superclusters,
          ...response.clusters.filter(c => !isDefined(c.superclusterId)),
        ]);
      } else {
        setClusters(response.clusters);
      }
    }, [response, useSuperclusters]);

    // Refetch and set the metadata
    useEffect(() => {
      const controller = new AbortController();
      if (analyzeBy) {
        API.metadata(controller.signal, apiParams, analyzeBy, reportIndex.analyzeByLimit ?? -1)
          .then(setMetadata)
          .catch(error => {
            if (error.name !== "AbortError") {
              console.error(error.message);
            }
          });
      }
      return () => controller.abort();
    }, [apiParams, analyzeBy]);

    if (!isLoading && clusters?.length === 0) {
      return (
        <Row style={{ margin: "0 24px 24px" }}>
          <Col span={24}>
            <Empty />
          </Col>
        </Row>
      );
    }

    const screenshotSentimentOptions = [
      { label: "N/A", value: "default" },
      { label: "🙂", value: "positive" },
      { label: "🤬", value: "negative" },
    ];

    const headerDetailOptions = [
      { label: "Less", value: "less" },
      { label: "More", value: "more" },
    ];

    const totalClassified = clusters?.map(c => c.size).reduce(sum, 0) ?? stat;

    if (!isLoading && isError) {
      return (
        <Result
          status="error"
          title="API Error"
          subTitle="Sorry, an error occurred! Please report this to your nearest Spiral representative."
        />
      );
    }

    const allClusters = clusters?.flatMap(c => (c.children ? c.children : [c]));

    const renderCluster = (
      c: SortableClusterSummary,
      i: number,
      path: string[],
      showClusterPath: string[],
      usePercent: boolean
    ) => {
      if (!allClusters) {
        return;
      }

      const className = c.children ? "superissue" : "subissue";
      const id = c.cluster;
      const hideEvidencesBelow = c.children ? undefined : reportIndex.hideEvidencesBelow;

      return (
        <Collapse.Panel
          id={id}
          key={c.cluster}
          className={className}
          header={
            <ClusterHeader
              index={i}
              cluster={c}
              path={[...path, c.cluster]}
              apiParams={apiParams}
              period={reportIndex.period}
              periodTotal={totalClassified}
              admin={admin}
              trendColors={trendColors}
              latestLength={reportIndex.latestLength}
              areaCurveType={reportIndex.areaCurveType}
              clusterInScreenshotMode={clusterInScreenshotMode}
              screenshotSentiment={screenshotSentiment}
              screenshotHeaderDetail={screenshotHeaderDetail}
              screenshotTitleSize={screenshotTitleSize}
              defaultSentiment={reportIndex.defaultSentiment}
              report={report}
              reportName={report.name}
              setDisableCollapse={setDisableCollapse}
              disableExport={disableExport}
              setDisableExport={setDisableExport}
              usePercent={usePercent}
            />
          }
        >
          <ClusterOverview
            apiParams={apiParams}
            key={c.cluster}
            cluster={c}
            admin={admin}
            includeHeader={false}
            analyzeBy={analyzeBy}
            overallData={metadata}
            type={chartType}
            setType={setChartType}
            normalized={normalized}
            setNormalized={v => {
              setNormalized(v);
              if (v) {
                setChartType("bar");
              }
            }}
            showEvidences={!c.children}
            crmName={customer.index.crmName}
            displayNames={reportIndex.displayNames}
            hideEvidencesBelow={hideEvidencesBelow}
            report={report}
            screenshotMode={clusterInScreenshotMode}
            setScreenshotMode={setScreenshotMode}
            allClusters={allClusters}
            usePercent={usePercent}
          />
          {c.children && (
            <Collapse
              bordered={false}
              destroyInactivePanel
              defaultActiveKey={showClusterPath.length > 0 ? showClusterPath[0] : undefined}
              collapsible={disableCollapse ? "disabled" : undefined}
            >
              {c.children
                .filter(
                  child =>
                    !isDefined(searchInput) || child.title.toLowerCase().includes(searchInput)
                )
                .sort(clusterComparator(sortBy))
                .map((subcluster, j) =>
                  renderCluster(
                    subcluster,
                    j,
                    [...path, c.cluster],
                    showClusterPath.length > 1 ? showClusterPath.slice(1) : [],
                    usePercent
                  )
                )}
            </Collapse>
          )}
        </Collapse.Panel>
      );
    };

    return (
      <Spin spinning={isLoading}>
        {admin && clusterInScreenshotMode && (
          <div
            style={{
              position: "fixed",
              width: 210,
              height: 240,
              background: "#FBFBFB",
              border: "2px solid #465fc3",
              left: 10,
              top: 200,
              zIndex: 99999,
              borderRadius: 5,
              boxShadow: "0 0 7px #AAA",
              padding: 10,
            }}
          >
            <Row>
              <Col span={20}>SCREENSHOT MODE</Col>
              <Col span={4}>
                <Button
                  icon={<CloseCircleOutlined />}
                  shape="circle"
                  color="red"
                  onClick={() => setClusterInScreenshotMode(undefined)}
                ></Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span>Force Sentiment:</span>
                <br />
                <Radio.Group
                  options={screenshotSentimentOptions}
                  value={screenshotSentiment === undefined ? "default" : screenshotSentiment}
                  onChange={({ target: { value } }) =>
                    setScreenshotSentiment(value === "default" ? undefined : value)
                  }
                ></Radio.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
                <span>Header Detail:</span>
                <br />
                <Radio.Group
                  options={headerDetailOptions}
                  value={screenshotHeaderDetail}
                  onChange={({ target: { value } }) => setScreenshotHeaderDetail(value)}
                ></Radio.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
                <span>Title Size: {screenshotTitleSize}px</span>
                <br />
                <Slider
                  value={screenshotTitleSize}
                  min={17}
                  max={22}
                  onChange={setScreenshotTitleSize}
                />
              </Col>
            </Row>
          </div>
        )}
        {admin && (
          <div style={{ margin: "0 24px" }}>
            {totalClassified.toLocaleString()} classified (
            {Math.round((totalClassified / stat) * 100).toLocaleString()}%)
          </div>
        )}
        {reportIndex.trendingWidget &&
          useSuperclusters &&
          !isDefined(searchInput) &&
          (reportIndex.trendingWidget.enabled ?? false) && (
            <Row style={{ margin: "0 24px 24px" }}>
              <Col span={24}>
                <Typography.Title level={4}>Trending</Typography.Title>
                <Collapse
                  bordered={false}
                  destroyInactivePanel
                  defaultActiveKey={showClusterPath.length == 1 ? showClusterPath[0] : undefined}
                  collapsible={disableCollapse ? "disabled" : undefined}
                >
                  {clusters &&
                    allClusters &&
                    clusters
                      .flatMap(c => (c.children ? c.children : [c]))
                      .flatMap(c => (c.children ? c.children : [c])) // HACK to support megaclusters
                      .filter(
                        c => c.sentiment === (reportIndex.trendingWidget?.sentiment ?? "negative")
                      )
                      .sort(clusterComparator(reportIndex.trendingWidget.sortBy ?? "trend"))
                      .slice(0, reportIndex.trendingWidget.topN ?? 5)
                      .map((c, i) => (
                        <Collapse.Panel
                          key={c.cluster}
                          className="subissue"
                          header={
                            <ClusterHeader
                              index={i}
                              cluster={c}
                              path={[c.cluster]}
                              apiParams={apiParams}
                              period={reportIndex.period}
                              periodTotal={totalClassified}
                              admin={admin}
                              trendColors={trendColors}
                              latestLength={reportIndex.latestLength}
                              areaCurveType={reportIndex.areaCurveType}
                              clusterInScreenshotMode={clusterInScreenshotMode}
                              defaultSentiment={reportIndex.defaultSentiment}
                              screenshotSentiment={screenshotSentiment}
                              screenshotHeaderDetail={screenshotHeaderDetail}
                              screenshotTitleSize={screenshotTitleSize}
                              report={report}
                              reportName={report.name}
                              setDisableCollapse={setDisableCollapse}
                              disableExport={disableExport}
                              setDisableExport={setDisableExport}
                              showCopyURL={true}
                              usePercent={true}
                            />
                          }
                        >
                          <ClusterOverview
                            apiParams={apiParams}
                            key={c.cluster}
                            cluster={c}
                            admin={admin}
                            includeHeader={false}
                            analyzeBy={analyzeBy}
                            overallData={metadata}
                            type={chartType}
                            setType={setChartType}
                            normalized={normalized}
                            setNormalized={v => {
                              setNormalized(v);
                              if (v) {
                                setChartType("bar");
                              }
                            }}
                            crmName={customer.index.crmName}
                            displayNames={reportIndex.displayNames}
                            hideEvidencesBelow={reportIndex.hideEvidencesBelow}
                            report={report}
                            screenshotMode={clusterInScreenshotMode}
                            setScreenshotMode={setScreenshotMode}
                            allClusters={allClusters}
                            usePercent={true}
                          />
                        </Collapse.Panel>
                      ))}
                </Collapse>
              </Col>
            </Row>
          )}
        <Row style={{ margin: "0 24px 24px" }}>
          <Col span={24}>
            {reportIndex.trendingWidget && (reportIndex.trendingWidget.enabled ?? false) && (
              <Typography.Title level={4}>All Issues</Typography.Title>
            )}
            <Collapse
              bordered={false}
              destroyInactivePanel
              defaultActiveKey={showClusterPath.length > 0 ? showClusterPath[0] : undefined}
              collapsible={disableCollapse ? "disabled" : undefined}
            >
              {clusters &&
                allClusters &&
                clusters
                  .filter(
                    c =>
                      !isDefined(searchInput) ||
                      c.title.toLowerCase().includes(searchInput.toLowerCase()) ||
                      c.children?.some(sc =>
                        sc.title.toLowerCase().includes(searchInput.toLowerCase())
                      )
                  )
                  .sort(clusterComparator(sortBy))
                  .map((c, i) =>
                    renderCluster(
                      c,
                      i,
                      [],
                      showClusterPath.length > 1 ? showClusterPath.slice(1) : [],
                      true
                    )
                  )}
            </Collapse>
          </Col>
        </Row>
      </Spin>
    );
  }
);

export const ReportOverviewCard = ({
  loading,
  dayCounts,
  reportIndex,
  legendText: legendText,
  stat,
  apiParams,
  analyzeBy,
  chartType,
  setChartTypeFn,
}: {
  loading: boolean;
  dayCounts?: MetadataField;
  reportIndex: ReportIndex;
  legendText: string;
  stat: number;
  apiParams: APIParams;
  analyzeBy?: string;
  chartType: ChartType;
  setChartTypeFn: (t: ChartType) => void;
}) => {
  return (
    <>
      <Row key="reportOverviewCard" style={{ margin: "0 24px 24px" }}>
        <Col span={24}>
          <Card className="reportOverviewCard">
            <Row>
              <Col span={16} style={{ textAlign: "center" }}>
                <Spin spinning={loading}>
                  <AntTooltip title={() => "Total Contacts in this Report"}>
                    <Statistic value={stat} />
                  </AntTooltip>
                  <TimeseriesChart
                    metadata={dayCounts}
                    graphheight={300}
                    daysToChart={100}
                    xaxis
                    yaxis
                    grid
                    percent={false}
                    preserve={"preserveEnd"}
                    xTickFormat={
                      reportIndex.xTickFormat ??
                      (reportIndex.period === "year" ? "MMM 'YY" : "MMM D")
                    }
                    minTickGap={reportIndex.minTickGap}
                    areaType={reportIndex.areaCurveType}
                    legendText={legendText}
                    showTooltip
                  />
                </Spin>
              </Col>
              <Col span={8} style={{ textAlign: "center" }}>
                {analyzeBy && (
                  <MetadataWidget
                    apiParams={apiParams}
                    analyzeBy={analyzeBy}
                    title={analyzeBy}
                    type={chartType}
                    setType={setChartTypeFn}
                    displayNames={reportIndex.displayNames}
                    limit={reportIndex.analyzeByLimit}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
