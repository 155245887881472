import { DatePicker, DatePickerProps, Spin, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";

import moment from "moment";
import { useNavigate, useParams } from "react-router";
import { getWeeklyReports } from "../../reportApi";

const { Title } = Typography;

interface Report {
  [entry: string]: number | string;
}

interface Reports {
  [title: string]: Report[];
}

export const WeeklyReportsPage = () => {
  const { endDate = moment().format("YYYY-MM-DD") } = useParams();
  const navigate = useNavigate();

  const onChange: DatePickerProps["onChange"] = (_date, dateString) => {
    navigate(`/admin/weekly-reports/${dateString}`);
  };

  const [data, setData] = useState<Reports>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const f = async () => {
      setData(await getWeeklyReports(endDate));
      setIsLoading(false);
    };
    f();
  }, [endDate]);

  if (data === undefined || isLoading) {
    return <Spin spinning />;
  }

  return (
    <div className="container selectable">
      <DatePicker onChange={onChange} value={moment(endDate)} />
      <br />
      <br />
      <Title level={3}>Weekly Reports for {endDate}</Title>
      <div>
        {Object.entries(data).map(([title, report]) => (
          <>
            <Title level={5}>{title}</Title>
            <Table
              dataSource={report}
              columns={Object.keys(report[0]).map(k => ({ title: k, dataIndex: k }))}
              pagination={false}
            />
            <br />
          </>
        ))}
      </div>
    </div>
  );
};
