import React, { useState, useEffect } from 'react';
import { Typography, Input, Button, List, Space, message, Switch } from 'antd';
import { type Customer } from "../../indexTypes";
import { getTagValidationSetCases, getTags, gptClassifications, updateTagHypothesis, updateValidationSetCase, updateTagName } from '../../reportApi';

interface Tag {
  id: string;
  name: string;
  hypothesis: string;
}

interface EvalCaseItem {
  id: string;
  set_id: string;
  tag_id: string;
  text: string;
  expected_result: boolean;
}

interface CaseClassification {
  classification: boolean;
  explanation: string;
}

interface TagEval {
  tag: Tag;
  evalCases: EvalCaseItem[];
  classification: CaseClassification[];
}

const { Title, Text, Paragraph } = Typography;

const TagManagerPage = ({ customer }: { customer: Customer }) => {
  const [tagEvals, setTagEvals] = useState<TagEval[]>([]);
  const [currentTagIndex, setCurrentTagIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isEditingTagName, setIsEditingTagName] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const customerId = customer.id;

  const saveHypothesis = async () => {
    const currentTagEval = tagEvals[currentTagIndex];
    try {
      await updateTagHypothesis(customerId, currentTagEval.tag.id, currentTagEval.tag.hypothesis);
      message.success('Hypothesis saved successfully');
    } catch (error) {
      message.error('Failed to save hypothesis');
    }
  };

  const rerunClassifier = async () => {
    setLoading(true);
    const currentTagEval = tagEvals[currentTagIndex];
    try {
      const newClassification = (await gptClassifications(currentTagEval.tag.hypothesis, currentTagEval.evalCases.map(c => c.text))) as CaseClassification[];
      setTagEvals(prevEvals => {
        const newEvals = [...prevEvals];
        newEvals[currentTagIndex] = {
          ...newEvals[currentTagIndex],
          classification: newClassification,
        };
        return newEvals;
      });
      message.success('Classifier rerun successfully');
    } catch (error) {
      message.error('Failed to rerun classifier');
    }
    setLoading(false);
  };

  const updateHypothesis = (value: string) => {
    setTagEvals(prevEvals => {
      const newEvals = [...prevEvals];
      newEvals[currentTagIndex].tag.hypothesis = value;
      return newEvals;
    });
  };

  const nextTag = () => {
    setCurrentTagIndex((prevIndex) => (prevIndex + 1) % tagEvals.length);
  };

  const previousTag = () => {
    setCurrentTagIndex((prevIndex) => (prevIndex + tagEvals.length - 1) % tagEvals.length);
  };

  const updateExpectedResult = async (caseItem: EvalCaseItem, newExpectedResult: boolean) => {
    try {
      await updateValidationSetCase(
        customerId,
        caseItem.set_id,
        caseItem.tag_id,
        caseItem.id,
        caseItem.text,
        newExpectedResult
      );

      setTagEvals(prevEvals => {
        const newEvals = [...prevEvals];
        const currentEval = newEvals[currentTagIndex];
        const updatedEvalCases = currentEval.evalCases.map(c => 
          c.id === caseItem.id ? { ...c, expected_result: newExpectedResult } : c
        );
        newEvals[currentTagIndex] = { ...currentEval, evalCases: updatedEvalCases };
        return newEvals;
      });

      message.success('Expected result updated successfully');
    } catch (error) {
      console.error(error);
      message.error('Failed to update expected result');
    }
  };

  const calculateAccuracy = (tagEvals: TagEval[]) => {
    const scores = tagEvals.map((tagEval) => tagEval.evalCases.map((caseItem, index) => {
      return caseItem.expected_result === tagEval.classification[index].classification ? 1 : 0;
    })) as number[][];
    const currentTagAccuracy = (scores[currentTagIndex].reduce((acc, score) => acc + score, 0) / scores[currentTagIndex].length) * 100;
    const totalAccuracy = (scores.flat().reduce((acc, score) => acc + score, 0) / scores.flat().length) * 100;
    return { tagAccuracy: currentTagAccuracy, totalAccuracy };
  };

  const handleTagNameEdit = () => {
    setIsEditingTagName(true);
    setNewTagName(tagEvals[currentTagIndex].tag.name);
  };

  const handleTagNameSave = async () => {
    setLoading(true);
    try {
      await updateTagName(customerId, tagEvals[currentTagIndex].tag.id, newTagName);
      setTagEvals(prevEvals => {
        const newEvals = [...prevEvals];
        newEvals[currentTagIndex].tag.name = newTagName;
        return newEvals;
      });
      setIsEditingTagName(false);
      message.success('Tag name updated successfully');
    } catch (error) {
      message.error('Failed to update tag name');
    }
    setLoading(false);
  };

  const handleTagNameCancel = () => {
    setIsEditingTagName(false);
    setNewTagName('');
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const tags = (await getTags(customerId)) as Tag[];

        const tagEvalsPromises = tags.map(async (tag) => {
          const evalCases = (await getTagValidationSetCases(customerId, tag.id)) as EvalCaseItem[];
          const classification = (await gptClassifications(tag.hypothesis, evalCases.map(c => c.text))) as CaseClassification[];
          return { tag, evalCases, classification };
        });

        const newTagEvals = await Promise.all(tagEvalsPromises);

        const scores = newTagEvals.map((tagEval) => tagEval.evalCases.map((caseItem, index) => {
          return caseItem.expected_result === tagEval.classification[index].classification ? 1 : 0;
        })) as number[][];
        const tagAccuracies = scores.map((score) => (score.reduce((acc, s) => acc + s, 0) / score.length) * 100);

        newTagEvals.sort((a, b) => {
          const aAccuracy = tagAccuracies[newTagEvals.findIndex(e => e.tag.id === a.tag.id)];
          const bAccuracy = tagAccuracies[newTagEvals.findIndex(e => e.tag.id === b.tag.id)];
          return aAccuracy - bAccuracy;
        });

        setTagEvals(newTagEvals);
      } catch (error) {
        console.error(error);
        message.error('Failed to load tag evaluations');
      }
      setLoading(false);
    })();
  }, [customerId]);

  if (tagEvals.length === 0) {
    return <div>Loading...</div>;
  }

  const currentTagEval = tagEvals[currentTagIndex];
  const { tagAccuracy, totalAccuracy } = calculateAccuracy(tagEvals);

  return (
    <Space direction="vertical" size="large" style={{ width: '100%', padding: '20px' }}>
      <Space direction="vertical">
        {isEditingTagName ? (
          <>
            <Input
              value={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
              style={{ width: 200 }}
            />
            <Space>
              <Button onClick={handleTagNameSave} loading={loading}>Save</Button>
              <Button onClick={handleTagNameCancel}>Cancel</Button>
            </Space>
          </>
        ) : (
          <>
            <Title level={3}>Tag: {currentTagEval.tag.name}</Title>
            <Button onClick={handleTagNameEdit} disabled={loading}>Edit Tag Name</Button>
          </>
        )}
      </Space>
      <Input.TextArea
        value={currentTagEval.tag.hypothesis}
        onChange={(e) => updateHypothesis(e.target.value)}
        rows={4}
        placeholder="Enter hypothesis"
        disabled={isEditingTagName}
      />
      <Space>
        <Button onClick={rerunClassifier} loading={loading} disabled={isEditingTagName}>Rerun Classifier</Button>
        <Button onClick={saveHypothesis} disabled={isEditingTagName}>Save Changes</Button>
        <Button onClick={previousTag} disabled={isEditingTagName}>Previous Tag</Button>
        <Button onClick={nextTag} disabled={isEditingTagName}>Next Tag</Button>
      </Space>
      <Paragraph>Tag Accuracy: {tagAccuracy.toFixed(2)}%</Paragraph>
      <Paragraph>Total Accuracy: {totalAccuracy.toFixed(2)}%</Paragraph>
      <List
        dataSource={currentTagEval.evalCases}
        renderItem={(caseItem, index) => (
          <List.Item>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text strong>{index + 1}. {caseItem.text}</Text>
              <Space>
                <Text type="secondary">Expected:</Text>
                <Switch
                  checked={caseItem.expected_result}
                  onChange={(checked) => updateExpectedResult(caseItem, checked)}
                  checkedChildren="True"
                  unCheckedChildren="False"
                />
              </Space>
              <Text type={caseItem.expected_result === currentTagEval.classification[index].classification ? 'success' : 'danger'}>
                Classified: {currentTagEval.classification[index].classification ? "True" : "False"}
              </Text>
              <Text type="secondary">Explanation: {currentTagEval.classification[index].explanation}</Text>
            </Space>
          </List.Item>
        )}
      />
    </Space>
  );
};

export default TagManagerPage;
