import { Collapse, Row, Spin } from "antd";
import React, { useCallback, useReducer } from "react";

import { getTaxonomyNode } from "../reportApi";
import { TaxonomyNodeData } from "../types/dashboardTypes";
import { TaxonomyNodeHeader } from "./TaxonomyNodeHeader";
import { TimeseriesChart } from "./TimeseriesChart";

interface TaxonomyNodeProps {
  taxonomyNodeData: TaxonomyNodeData;
  parentId?: string;
  scale?: number;
  granularity: string;
  taxonomyId: string;
  startDate: string;
  endDate: string;
  viewId: string;
}

interface TaxonomyNodeState {
  isLoading: boolean;
  childrenData: TaxonomyNodeData[];
}

const initialState: TaxonomyNodeState = {
  isLoading: false,
  childrenData: [],
};

type TaxonomyNodeAction =
  | { type: "SET_LOADING" }
  | { type: "SET_CHILDREN_DATA"; payload: TaxonomyNodeData[] };

function taxonomyNodeReducer(
  state: TaxonomyNodeState,
  action: TaxonomyNodeAction
): TaxonomyNodeState {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: true, childrenData: [] };
    case "SET_CHILDREN_DATA":
      return { ...state, childrenData: action.payload, isLoading: false };
    default:
      return state;
  }
}

export const TaxonomyNode: React.FC<TaxonomyNodeProps> = ({
  taxonomyNodeData,
  parentId = undefined,
  scale = undefined,
  granularity,
  taxonomyId,
  startDate,
  endDate,
  viewId,
}: TaxonomyNodeProps) => {
  const [state, dispatch] = useReducer(taxonomyNodeReducer, initialState);

  const { id, name, childIds, total, percent, mean, rawCounts, percentCounts } = taxonomyNodeData;

  const isLeafNode = childIds.length === 0;

  const fetchChildNodes = useCallback(async () => {
    dispatch({ type: "SET_LOADING" });
    try {
      const childrenPromises = childIds.map(childId =>
        getTaxonomyNode(taxonomyId, childId, viewId, {
          granularity,
          startDate,
          endDate,
        })
      );
      const childrenData = await Promise.all(childrenPromises);
      const childrenDataSorted = childrenData.sort((a, b) => b.total - a.total);
      dispatch({ type: "SET_CHILDREN_DATA", payload: childrenDataSorted });
    } catch (error) {
      console.error("Error fetching child nodes:", error);
    }
  }, [childIds, taxonomyId, viewId, granularity, startDate, endDate]);

  const maxValue = percentCounts.data.reduce((acc, curr) => {
    return curr.value > acc ? curr.value : acc;
  }, 0);
  const chartScale = scale || maxValue;

  return (
    <div>
      <Collapse
        bordered={false}
        destroyInactivePanel
        onChange={() => {
          fetchChildNodes();
        }}
      >
        <Collapse.Panel
          key={id}
          header={
            <TaxonomyNodeHeader
              taxonomyNodeId={id}
              name={name}
              data={percentCounts}
              total={total}
              percent={percent}
              mean={mean}
              granularity={granularity}
              scale={chartScale}
              parentId={parentId}
              nodeChildren={childIds}
            />
          }
          className={childIds.length > 0 ? "superissue" : "subissue"}
        >
          <Collapse bordered={false} destroyInactivePanel>
            <Row className="metadata" style={{ padding: "24px", marginLeft: "0" }}>
              <TimeseriesChart
                metadata={percentCounts}
                secondaryMetadata={rawCounts}
                graphheight={320}
                xaxis
                yaxis
                grid
                animationDuration={150}
                preserve={"preserveEnd"}
                xTickFormat={"MMM D"}
                showTooltip
              />
              {isLeafNode && <div>TODO Evidences Table Here</div>}
            </Row>
            <div style={{ minHeight: "80px" }}>
              <Spin
                spinning={state.isLoading}
                tip="Loading issues..."
                style={{ minHeight: "80px" }}
              >
                {state.childrenData.map(childData => (
                  <TaxonomyNode
                    key={childData.id}
                    taxonomyNodeData={childData}
                    viewId={viewId}
                    parentId={id}
                    scale={chartScale}
                    granularity={granularity}
                    taxonomyId={taxonomyId}
                    startDate={startDate}
                    endDate={endDate}
                  />
                ))}
              </Spin>
            </div>
          </Collapse>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};
