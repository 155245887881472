import React, { useState } from 'react';

import { Cascader, Space } from 'antd';
import type { DefaultOptionType } from 'antd/es/cascader';

import { groupBy, isNumber } from 'lodash';
import { useDashboardContext } from '../hooks';
import { FilterOptions, Settings } from '../types/dashboardTypes';


export const MultiFilter: React.FC<{ viewFilters: Settings["filters"], filterOptions: FilterOptions }> = ({ viewFilters, filterOptions }) => {
  const { dispatch } = useDashboardContext();
  const [ error, setError ] = useState<string | undefined>(undefined);

  interface Option {
    key: string | number;
    value: string | number;
    label: string | number;
    children?: Option[];
    parent?: string;
    parentDisplayName?: string;
  }


  const options = Object.keys(viewFilters).reduce((acc: Option[], fieldName: string) => {
    const fieldMetadata = viewFilters[fieldName];

    if (fieldName in filterOptions) {
      acc.push({
        value: fieldName,
        label: fieldMetadata.display_name,
        key: fieldName,
        children: filterOptions[fieldName].map((entry) => ({
          key: entry,
          value: entry,
          label: entry,
          parent: fieldName,
          parentDisplayName: fieldMetadata.display_name
        })),
      });
    }
    return acc;
  }, []);


  const filtersToExpression = (values: (string | number)[][]) => {
    const groupedFilters = groupBy(values, (o => o[0]));
    const expressions = Object.keys(groupedFilters).map((key) => {
      return {
        expr: {
          op_id: "ANY",
          expressions: groupedFilters[key].map((val) => {
            return {
              expr: {
                op_id: "EQ",
                lhs: {
                  expr: {
                    field_id: isNumber(val[1]) ? "metadata_numeric_value" : "metadata_value",
                    metadata_key: val[0]
                  }
                },
                rhs: {
                  expr: val[1]
                }
              }
            }
          })
        }
      }
    });

    const filterExpression = {
      version: 1,
      parsed: {
        expr: { op_id: "ALL", expressions}
      }
    }
    return filterExpression;
  };

  const onChange = (values: (string | number)[][]) => {
    setError(undefined);
    // Filter out the parent values
    const selectedOptions = values.filter((value) => value.length > 1);

    const MAX_FILTERS = 10;
    if (selectedOptions.length > MAX_FILTERS) {
      setError(`Please select ${MAX_FILTERS} or fewer filters`);
      return;
    }
    const formattedFilters = filtersToExpression(selectedOptions);
    dispatch({ type: "SET_FILTERS", payload: formattedFilters });
  };

  const renderSelectedFilters = (labels: string[], selectedFields: DefaultOptionType[] | undefined) => {
    if (!selectedFields || selectedFields.length === 0) {
      return;
    }

    const option = selectedFields[selectedFields.length - 1];
    const label = labels[labels.length - 1];
    if (selectedFields.length > 1 && option.parent) {
      return <span key={option.value}>{option.parentDisplayName}: {label}</span>;
    } else {
      return <span key={option.value}>{option.label}: All</span>;
    }
  }

  return (
    <Space>
      <Cascader
        dropdownClassName="dashboard-multi-filter-cascader"
        style={{ minWidth: 140, width: "100%" }}
        placeholder="Filter"
        options={options}
        onChange={onChange}
        displayRender={renderSelectedFilters}
        multiple
        status={error ? "error" : undefined}
        maxTagCount={7} // Selected options are truncated in the UI after maxTagCount items
      />
      {error && <span style={{ color: "red" }}>{error}</span>}
    </Space>
  );
}
